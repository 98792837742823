import dayjs from 'dayjs';
import useStore from 'Hooks/useStore';

/**
 * Validation:
 * 1. Manager+ ignore validation
 * 2. Staff ignore validation only for past bookings
 * 3. Pax count cannot exceed available capacity
 */
export const useTicketValidation = () => {
	const { isStaff, isManager } = useStore();

	const validate = (passengerCount: number, capacity: number, startDate: Date) => {
		if (isManager) {
			// No validation required
			return true;
		}

		const isBeforeNow = dayjs(startDate).isBefore(dayjs());

		if (isBeforeNow) {
			// Staff+ can do anything to past bookings
			// Otherwise, users cannot book in the past
			return isStaff;
		}

		return passengerCount <= capacity;
	};

	return validate;
};
