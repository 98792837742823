import React from 'react';

import MultiStopTicketSelectionTab from 'Modules/MultiStopWizard/TicketSelectionTab';
import {
	TicketSelectionTab,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TicketSelectionTab';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { RouteEntity } from 'Models/Entities';
import { BookingEntity } from 'Models/Entities';

export interface TicketsTabProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (data: BookingWizardData) => void;
	route: RouteEntity | null;
	selectedTrips: SelectedTrips;
}

export function TicketsTab({
	wizardData,
	onUpdateWizardData,
	route,
	selectedTrips,
}: TicketsTabProps) {
	// Feature: Multi-stop - tickets tab
	const isMultiStop = true;
	if (isMultiStop) {
		return (
			<MultiStopTicketSelectionTab
				wizardData={wizardData}
				saveChanges={onUpdateWizardData}
				selectedTrips={selectedTrips}
			/>
		);
	}

	return (
		<TicketSelectionTab
			wizardData={wizardData}
			saveChanges={onUpdateWizardData}
			route={route}
			selectedTrips={selectedTrips}
		/>
	);
}
