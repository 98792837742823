import { action, observable } from 'mobx';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import * as Enums from 'Models/Enums';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import {
	FeeEntity,
	GiftCertificateUsageEntity,
	UserEntity,
} from 'Models/Entities';
import {
	AdditionalOption,
	BaseBookingCreationData, PassengersInfo,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface IBookingFormState extends BaseBookingCreationData {
	email: string;
	user: UserEntity;
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[];
	existingTransactionId?: string;
	bypassSpaceCheck: boolean;
	location: Enums.locationType;
	forEftpos: boolean;
}

export class BookingFormState implements IBookingFormState {
	@observable
	email: string;

	@observable
	cargoMake: string;

	@observable
	cargoModel: string;

	@observable
	user: UserEntity = new UserEntity();

	@observable
	giftCertificateUsages: GiftCertificateUsageEntity[] = [];

	@observable
	transactionFees: FeeEntity[] = [];

	@observable
	bookingBeingEdited?: CheckInBookingOverviewDto;

	@observable
	bookingToEdit?: string;

	@observable
	userId: string;

	@observable
	departureTicketId: string;

	@observable
	departingTripOptions: AdditionalOption[] = [];

	@observable
	returningTicketId: string;

	@observable
	returningTripOptions: AdditionalOption[] = [];

	@observable
	adultTickets: PassengersInfo[];

	@observable
	childTickets: PassengersInfo[];

	@observable
	infantTickets: PassengersInfo[];

	@observable
	passengerDTickets: PassengersInfo[];

	@observable
	passengerETickets: PassengersInfo[];

	@observable
	passengerFTickets: PassengersInfo[];

	@observable
	passengerGTickets: PassengersInfo[];

	@observable
	passengerHTickets: PassengersInfo[];

	@observable
	driverFirstName?: string;

	@observable
	driverLastName?: string;

	@observable
	driverPhone?: string;

	@observable
	cargoIdentification?: string;

	@observable
	hiredVehicle?: boolean;

	@observable
	cargoTypeId?: string;

	@observable
	vehicleLengthId?: string;

	@observable
	vehicleWeightId?: string;

	@observable
	trailerLengthId?: string;

	@observable
	trailerTypeId?: string;

	@observable
	existingTransactionId?: string;

	@observable
	bypassSpaceCheck: boolean;

	@observable
	forEftpos: boolean;

	@observable
	note: string;

	@observable
	location: Enums.locationType;

	constructor(attributes?: Partial<IBookingFormState>) {
		this.assignAttributes(attributes);
	}

	@action
	private assignAttributes(attributes?: Partial<IBookingFormState>) {
		if (attributes === undefined) {
			return;
		}
		if (isNotNullOrUndefined(attributes.bookingToEdit)) {
			this.bookingToEdit = attributes.bookingToEdit;
		}
		if (isNotNullOrUndefined(attributes.userId)) {
			this.userId = attributes.userId;
		}
		if (isNotNullOrUndefined(attributes.departureTicketId)) {
			this.departureTicketId = attributes.departureTicketId;
		}
		if (isNotNullOrUndefined(attributes.departingTripOptions) && Array.isArray(attributes.departingTripOptions)) {
			this.departingTripOptions = attributes.departingTripOptions;
		}
		if (isNotNullOrUndefined(attributes.returningTicketId)) {
			this.returningTicketId = attributes.returningTicketId;
		}
		if (isNotNullOrUndefined(attributes.returningTripOptions) && Array.isArray(attributes.returningTripOptions)) {
			this.returningTripOptions = attributes.returningTripOptions;
		}
		if (isNotNullOrUndefined(attributes.adultTickets)) {
			this.adultTickets = attributes.adultTickets;
		}
		if (isNotNullOrUndefined(attributes.childTickets)) {
			this.childTickets = attributes.childTickets;
		}
		if (isNotNullOrUndefined(attributes.infantTickets)) {
			this.infantTickets = attributes.infantTickets;
		}
		if (isNotNullOrUndefined(attributes.passengerDTickets)) {
			this.passengerDTickets = attributes.passengerDTickets;
		}
		if (isNotNullOrUndefined(attributes.passengerETickets)) {
			this.passengerETickets = attributes.passengerETickets;
		}
		if (isNotNullOrUndefined(attributes.passengerFTickets)) {
			this.passengerFTickets = attributes.passengerFTickets;
		}
		if (isNotNullOrUndefined(attributes.passengerGTickets)) {
			this.passengerGTickets = attributes.passengerGTickets;
		}
		if (isNotNullOrUndefined(attributes.passengerHTickets)) {
			this.passengerHTickets = attributes.passengerHTickets;
		}
		if (isNotNullOrUndefined(attributes.driverFirstName)) {
			this.driverFirstName = attributes.driverFirstName;
		}
		if (isNotNullOrUndefined(attributes.driverLastName)) {
			this.driverLastName = attributes.driverLastName;
		}
		if (isNotNullOrUndefined(attributes.driverPhone)) {
			this.driverPhone = attributes.driverPhone;
		}
		if (isNotNullOrUndefined(attributes.cargoIdentification)) {
			this.cargoIdentification = attributes.cargoIdentification;
		}
		if (isNotNullOrUndefined(attributes.hiredVehicle)) {
			this.hiredVehicle = attributes.hiredVehicle;
		}
		if (isNotNullOrUndefined(attributes.cargoTypeId)) {
			this.cargoTypeId = attributes.cargoTypeId;
		}
		if (isNotNullOrUndefined(attributes.vehicleLengthId)) {
			this.vehicleLengthId = attributes.vehicleLengthId;
		}
		if (isNotNullOrUndefined(attributes.vehicleWeightId)) {
			this.vehicleWeightId = attributes.vehicleWeightId;
		}
		if (isNotNullOrUndefined(attributes.trailerLengthId)) {
			this.trailerLengthId = attributes.trailerLengthId;
		}
		if (isNotNullOrUndefined(attributes.trailerTypeId)) {
			this.trailerTypeId = attributes.trailerTypeId;
		}
		if (isNotNullOrUndefined(attributes.existingTransactionId)) {
			this.existingTransactionId = attributes.existingTransactionId;
		}
		if (isNotNullOrUndefined(attributes.email)) {
			this.email = attributes.email;
		}
		if (isNotNullOrUndefined(attributes.cargoMake)) {
			this.cargoMake = attributes.cargoMake;
		}
		if (isNotNullOrUndefined(attributes.cargoModel)) {
			this.cargoModel = attributes.cargoModel;
		}
		if (isNotNullOrUndefined(attributes.user)) {
			this.user = attributes.user;
		}
		if (isNotNullOrUndefined(attributes.bypassSpaceCheck)) {
			this.bypassSpaceCheck = attributes.bypassSpaceCheck;
		}
		if (isNotNullOrUndefined(attributes.note)) {
			this.note = attributes.note;
		}
	}

	public reset(): void {
		this.adultTickets = [];
		this.childTickets = [];
		this.infantTickets = [];
		this.passengerDTickets = [];
		this.passengerETickets = [];
		this.passengerFTickets = [];
		this.passengerGTickets = [];
		this.passengerHTickets = [];
	}
}
