import React from 'react';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { TripSummary } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/TripSummary';
// import { FerryFilters } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/FerryFilters';
import { FerryFilters } from 'Modules/MultiStopWizard/FerryFilters';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { SidebarStatus } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import { LocationEntity, RouteEntity } from 'Models/Entities';
import { BookingEntity } from 'Models/Entities';

export interface BookingWizardSideBarProps {
	sidebarStatus: SidebarStatus;
	wizardData: BookingWizardData;
	onUpdateWizardData: (wizardData: BookingWizardData) => void;
	route: RouteEntity | null;
	setRoute: (route: RouteEntity | null) => void;
	selectedTrips: SelectedTrips;
	isIpad: boolean;
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
}

export function BookingWizardSidebar({
	wizardData,
	onUpdateWizardData,
	sidebarStatus,
	route,
	setRoute,
	selectedTrips,
	isIpad,
	locations,
}: BookingWizardSideBarProps) {
	const showSideBar = sidebarStatus !== SidebarStatus.NO_SIDEBAR && !isIpad;
	return (
		<div className={`filter-sidebar-container ${showSideBar ? '' : 'hide'} `}>
			{/*
				If this ternary fails, we will be shown the trip summary regardless of whether the status says no
				sidebar. That is fine because if the status is no sidebar then a class to hide the component will be
				added, rendering the summary invisible
			*/}
			{ sidebarStatus === SidebarStatus.FILTERS
				? (
					<>
						<div className="filter-container">
							<FerryFilters
								wizardData={wizardData}
								onUpdateFilters={onUpdateWizardData}
								route={route}
								setRoute={setRoute}
								locations={locations}
							/>
						</div>
					</>
				)
				: (
					<>
						<TripSummary
							wizardData={wizardData}
							selectedTrips={selectedTrips}
							onUpdateWizardData={onUpdateWizardData}
						/>
					</>
				) }
		</div>
	);
}
