import { action } from 'mobx';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { bookingStatus, bookingTransactionStatus } from 'Models/Enums';
import {
	AdditionalBookingOptionEntity,
	CargoEntity,
	FerryTripEntity,
	TowOnEntity,
	UserEntity,
} from 'Models/Entities';
import { getMeasurementLabelFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { getFullName, upperCaseFirst } from 'Util/StringUtils';
import * as Models from 'Models/Entities';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import { getDefaultPassengerInfo } from '../../../FerryTripBookingWizard/BookingWizardData';

export interface ICheckInBookingOverviewDtoDto {
	id: string;
	humanReadableId: string;
	bookedSummary: BookingSummary;
	user: UserEntity;
	transactionStatus: bookingTransactionStatus;
	bookingStatus: bookingStatus;
	checkedIn: boolean;
	isInvoiced: boolean;
	desynced: boolean;
	returnBooking?: CheckInBookingOverviewDto;
	returnBookingForId?: string;
	firstTimeOnRoute: boolean;
}

export class CheckInBookingOverviewDto implements ICheckInBookingOverviewDtoDto {
	constructor(attributes?: Partial<ICheckInBookingOverviewDtoDto>) {
		this.assignAttributes(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	private assignAttributes(attributes?: Partial<ICheckInBookingOverviewDtoDto>) {
		if (isNullOrUndefined(attributes)) {
			return;
		}
		if (isNotNullOrUndefined(attributes.id)) {
			this.id = attributes.id;
		}
		if (isNotNullOrUndefined(attributes.humanReadableId)) {
			this.humanReadableId = attributes.humanReadableId;
		}
		if (isNotNullOrUndefined(attributes.checkedIn)) {
			this.checkedIn = attributes.checkedIn;
		}
		if (isNotNullOrUndefined(attributes.desynced)) {
			this.desynced = attributes.desynced;
		}
		if (isNotNullOrUndefined(attributes.isInvoiced)) {
			this.isInvoiced = attributes.isInvoiced;
		}
		if (isNotNullOrUndefined(attributes.user)) {
			if (attributes.user === null) {
				this.user = attributes.user;
			} else if (attributes.user instanceof Models.UserEntity) {
				this.user = attributes.user;
			} else {
				this.user = new Models.UserEntity(attributes.user);
			}
		}
		if (isNotNullOrUndefined(attributes.bookedSummary)) {
			//
			// New alteration booking summary section
			//
			this.bookedSummary = new BookingSummary({
				userFirstName: attributes.bookedSummary.userFirstName,
				userLastName: attributes.bookedSummary.userLastName,
				adultPassengerTickets: attributes.bookedSummary.adultPassengerTickets,
				childPassengerTickets: attributes.bookedSummary.childPassengerTickets,
				infantPassengerTickets: attributes.bookedSummary.infantPassengerTickets,
				passengerDTickets: attributes.bookedSummary.passengerDTickets,
				passengerETickets: attributes.bookedSummary.passengerETickets,
				passengerFTickets: attributes.bookedSummary.passengerFTickets,
				passengerGTickets: attributes.bookedSummary.passengerGTickets,
				passengerHTickets: attributes.bookedSummary.passengerHTickets,
				driverFirstName: attributes.bookedSummary.driverFirstName,
				driverLastName: attributes.bookedSummary.driverLastName,
				driverPhone: attributes.bookedSummary.driverPhone,
				ferryTrip: new FerryTripEntity(attributes.bookedSummary.ferryTrip),
				totalCost: attributes.bookedSummary.totalCost,
				maxRefund: attributes.bookedSummary.maxRefund,
				paidBookingPrice: attributes.bookedSummary.paidBookingPrice,
				nonRefundableAmount: attributes.bookedSummary.nonRefundableAmount,
				additionalBookingOptions: [],
				note: attributes.bookedSummary.note,
			});
			const { cargoInfo: vehicleInfoFromNewSummary } = attributes
				.bookedSummary;

			if (!isNotNullOrUndefined(vehicleInfoFromNewSummary)) {
				this.bookedSummary.cargoInfo = null;
			} else if (vehicleInfoFromNewSummary instanceof CargoEntity) {
				this.bookedSummary.cargoInfo = attributes
					.bookedSummary
					.cargoInfo;
			} else {
				this.bookedSummary.cargoInfo = new CargoEntity(
					vehicleInfoFromNewSummary,
				);
			}
			const { towOnInfo: trailerInfoFromNewSummary } = attributes
				.bookedSummary;

			if (!isNotNullOrUndefined(trailerInfoFromNewSummary)) {
				this.bookedSummary.towOnInfo = null;
			} else if (trailerInfoFromNewSummary instanceof TowOnEntity) {
				this.bookedSummary.towOnInfo = trailerInfoFromNewSummary;
			} else {
				this.bookedSummary.towOnInfo = new TowOnEntity(
					trailerInfoFromNewSummary,
				);
			}
			const { additionalBookingOptions: optionsFromNewSummary } = attributes
				.bookedSummary;
			if (Array.isArray(optionsFromNewSummary)) {
				this.bookedSummary
					.additionalBookingOptions = optionsFromNewSummary
						.map(option => {
							return {
								quantity: option.quantity,
								option: option.option instanceof AdditionalBookingOptionEntity
									? option.option
									: new AdditionalBookingOptionEntity(option.option),
							};
						});
			}
		}
		if (isNotNullOrUndefined(attributes.firstTimeOnRoute)) {
			this.firstTimeOnRoute = attributes.firstTimeOnRoute;
		}
		if (isNotNullOrUndefined(attributes.bookingStatus)) {
			this.bookingStatus = attributes.bookingStatus;
		}
		if (isNotNullOrUndefined(attributes.transactionStatus)) {
			this.transactionStatus = attributes.transactionStatus;
		}
		if (isNotNullOrUndefined(attributes.returnBookingForId)) {
			this.returnBookingForId = attributes.returnBookingForId;
		}
		if (isNotNullOrUndefined(attributes.returnBooking)) {
			if (attributes.returnBooking === null) {
				this.returnBooking = attributes.returnBooking;
			} else {
				this.returnBooking = new CheckInBookingOverviewDto(attributes.returnBooking);
			}
		}
	}

	id: string;
	humanReadableId: string;
	bookedSummary: BookingSummary;
	user: UserEntity;
	transactionStatus: bookingTransactionStatus;
	bookingStatus: bookingStatus;
	checkedIn: boolean;
	isInvoiced: boolean;
	desynced: boolean;
	returnBooking?: CheckInBookingOverviewDto;
	returnBookingForId?: string;
	firstTimeOnRoute: boolean;

	/**
	 * Returns text for make of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleMake(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoType && cargoInfo.cargoType.cargoMake) {
				return cargoInfo.cargoType.cargoMake;
			}
		}

		return '-';
	}

	public getDisplayVehicleLength(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.selectedLengthId) {
				return getMeasurementLabelFromId(cargoInfo.selectedLengthId);
			}
		}

		return '-';
	}

	public getDisplayVehicleWeight(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.selectedWeightId) {
				return getMeasurementLabelFromId(cargoInfo.selectedWeightId);
			}
		}

		return '-';
	}

	/**
	 * Returns text for model of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleModel(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoType && cargoInfo.cargoType.cargoModel) {
				return cargoInfo.cargoType.cargoModel;
			}
		}

		return '-';
	}

	/**
	 * Returns text for rego of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleRego(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoIdentification) {
				return cargoInfo.cargoIdentification;
			}
		}

		return '-';
	}

	/**
	 * Returns text for type of trailer (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayTrailerType(): string {
		if (this.bookedSummary) {
			const { towOnInfo } = this.bookedSummary;
			if (towOnInfo && towOnInfo.towOnType) {
				return upperCaseFirst(towOnInfo.towOnType.label.toLowerCase());
			}
		}

		return '-';
	}

	/**
	 * Returns text for length of trailer (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayTrailerLength(): string {
		if (this.bookedSummary) {
			const { towOnInfo } = this.bookedSummary;
			if (towOnInfo && towOnInfo.selectedLengthId) {
				return getMeasurementLabelFromId(towOnInfo.selectedLengthId);
			}
		}

		return '-';
	}

	public hasVehicle(): boolean {
		return isNotNullOrUndefined(this.bookedSummary?.cargoInfo);
	}

	/**
	 * If booking has a vehicle, driver name is used.
	 *
	 * Otherwise user account name is used.
	 */
	public getFullName() {
		if (!this.user || !this.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		const {
			cargoInfo,
			driverFirstName,
			driverLastName,
		} = this.bookedSummary;

		const isVehicleBooking = isNotNullOrUndefined(cargoInfo);
		const name = isVehicleBooking
			? getFullName(driverFirstName, driverLastName, true)
			: this.user.getFullName(true);

		return name;
	}

	/**
	 * Returns formatted text for adult passenger count
	 *
	 * e.g. 2x Adults
	 */
	public getDisplayAdultCount(): string {
		if (!this.bookedSummary) {
			return '- Adults';
		}
		return `${this.bookedSummary.adultPassengerTickets.length}x Adults`;
	}

	/**
	 * Returns formatted text for child passenger count
	 *
	 * e.g. 2x Children
	 */
	public getDisplayChildCount(): string {
		if (!this.bookedSummary) {
			return '- Children';
		}
		return `${this.bookedSummary.childPassengerTickets.length}x Children`;
	}

	/**
	 * Returns formatted text for infant passenger count
	 *
	 * e.g. 2x Infants
	 */
	public getDisplayInfantCount(): string {
		if (!this.bookedSummary) {
			return '- Infants';
		}
		return `${this.bookedSummary.infantPassengerTickets.length}x Infants`;
	}

	static createBlank() {
		return new CheckInBookingOverviewDto({
			user: new Models.UserEntity(),
			bookedSummary: new BookingSummary({
				userFirstName: '',
				userLastName: '',
				adultPassengerTickets: [getDefaultPassengerInfo('A')],
				childPassengerTickets: [],
				infantPassengerTickets: [],
				passengerDTickets: [],
				passengerETickets: [],
				passengerFTickets: [],
				passengerGTickets: [],
				passengerHTickets: [],
				driverPhone: '',
				driverFirstName: '',
				driverLastName: '',
				ferryTrip: new Models.FerryTripEntity(),
				totalCost: 0,
				paidBookingPrice: 0,
				maxRefund: 0,
				nonRefundableAmount: 0,
				additionalBookingOptions: [],
			}),
		});
	}
}
