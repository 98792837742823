import { create } from 'zustand';

import {
	//
	LocationEntity,
	RouteEntity,
	SegmentEntity,
	StopEntity,
} from 'Models/Entities';
import { fetchJourneyElements, JourneyElementsDto } from 'Services/Api/_HumanWritten/JourneyService';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

interface JourneyElementsStore {
	// states
	isLoading: boolean;
	errors?: any;

	// entities
	locations: LocationEntity[];
	routes: RouteEntity[];
	segments: SegmentEntity[];
	stops: StopEntity[];

	// functions
	setElements(elements: JourneyElementsDto): void;
	setErrors(errors: any): void;
}

const journeyElementsStore = create<JourneyElementsStore>(set => ({
	isLoading: true,
	errors: {},
	locations: [],
	routes: [],
	segments: [],
	stops: [],
	setElements(elements: JourneyElementsDto) {
		set({
			...linkJourneyElementAssociations(elements),
			isLoading: false,
		});
	},
	setErrors(errors: any) {
		set({ errors, isLoading: false });
	},
}));

// ======== Hooks ======== //

export const retrieveJourneyElementsAsync = async () => {
	try {
		const elements = await fetchJourneyElements();
		journeyElementsStore.getState().setElements(elements);
	} catch (errors: any) {
		journeyElementsStore.getState().setErrors(errors);
	}
};

/**
 * Returns all locations in the application. Note: it will cause re-render if data is updated.
 */
export const useLocations = () => {
	return journeyElementsStore(x => x.locations);
};

/**
 * Returns all routes in the application. Note: it will cause re-render if data is updated.
 */
export const useRoutes = () => {
	return journeyElementsStore(x => x.routes);
};

/**
 * Returns all segments in the application. Note: it will cause re-render if data is updated.
 */
export const useSegments = () => {
	return journeyElementsStore(x => x.segments);
};

/**
 * Returns all stops in the application. Note: it will cause re-render if data is updated.
 */
export const useStops = () => {
	return journeyElementsStore(x => x.stops);
};

/**
 * Returns true journey elements are still being loaded.
 */
export const useIsJourneyElementsLoading = () => {
	return journeyElementsStore(x => x.isLoading);
};

export const filterDestinations = (departureLocationId: string): LocationEntity[] => {
	const possibleDestinations = new Set<LocationEntity>();

	// Iterate through all routes
	for (const route of journeyElementsStore.getState().routes) {
		let foundSelectedStop = false;

		for (const stop of route.stops.slice().sort(sortByOrder())) {
			// If the stop matches the selected location, mark it as the starting point
			if (!foundSelectedStop && stop.location.id === departureLocationId) {
				foundSelectedStop = true;
			} else if (foundSelectedStop) {
				// If the selected location has been found, collect all stops that follow
				possibleDestinations.add(stop.location);
			}
		}
	}

	return Array.from(possibleDestinations.values());
};

export const getDefaultLocationIds = () => {
	const { routes } = journeyElementsStore.getState();

	if (!routes.length) {
		return null;
	}

	const model = {
		startLocationId: routes[0].departureId,
		endLocationId: routes[0].destinationId,
	};

	const { defaultRouteId } = whiteLabelStore;

	if (!defaultRouteId) {
		return model;
	}

	const match = routes.find(x => x.id === defaultRouteId);
	if (!match) {
		return model;
	}

	return {
		startLocationId: match.departureId,
		endLocationId: match.destinationId,
	};
};

export const isTravelPossible = (startLocationId: string, endLocationId: string) => {
	return filterDestinations(startLocationId)
		.map(x => x.id)
		.includes(endLocationId);
};

export const getLocationById = (locationId: string) => {
	return journeyElementsStore.getState().locations.find(x => x.id === locationId);
};

export const doesLocationExists = (locationId: string) => {
	return journeyElementsStore.getState().locations.findIndex(x => x.id === locationId) >= 0;
};

// ======== Util functions ======== //

const linkJourneyElementAssociations = ({
	//
	locations,
	routes,
	segments,
	stops,
}: JourneyElementsDto) => {
	for (const route of routes) {
		const departure = locations.find(x => x.id === route.departureId);
		if (departure) {
			route.departure = departure;
			if (!departure.departureRoutes.includes(route)) {
				departure.departureRoutes.push(route);
			}
		}
		const destination = locations.find(x => x.id === route.destinationId);
		if (destination) {
			route.destination = destination;
			if (!destination.destinationRoutes.includes(route)) {
				destination.destinationRoutes.push(route);
			}
		}
	}

	for (const segment of segments) {
		const route = routes.find(x => x.id === segment.routeId);
		if (route) {
			segment.route = route;
			route.segments.push(segment);
		}
		const startStop = stops.find(x => x.id === segment.startStopId);
		if (startStop) {
			segment.startStop = startStop;
		}
		const endStop = stops.find(x => x.id === segment.endStopId);
		if (endStop) {
			segment.endStop = endStop;
		}
	}

	for (const stop of stops) {
		const route = routes.find(x => x.id === stop.routeId);
		if (route) {
			stop.route = route;
			route.stops.push(stop);
		}
		const location = locations.find(x => x.id === stop.locationId);
		if (location) {
			stop.location = location;
			stop.location.stops.push(stop);
		}
	}

	return {
		locations,
		routes,
		segments,
		stops,
	};
};

function sortByOrder<T extends { order: number }>(descending: boolean = false): (a: T, b: T) => number {
	return (a: T, b: T) => {
		if (a.order > b.order) return descending ? -1 : 1;
		if (a.order < b.order) return descending ? 1 : -1;
		return 0;
	};
}
