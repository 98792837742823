import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button } from 'Views/Components/Button/Button';
import React from 'react';
import { BookingWizardData, TabSelected } from '../../../BookingWizardData';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { UpdateWizardData } from './FerryFilterPassengers';

export interface BookingTypeButtonsProps {
	wizardData: BookingWizardData;
	onVehicleTab: boolean;
	onPassengerTab: boolean;
	editMode: boolean;
	vehicleButtonLabel: string;
	passengerButtonLabel: string;
	onUpdateFilters: UpdateWizardData;
}

export function BookingTypeButtons({
	wizardData,
	onVehicleTab,
	onPassengerTab,
	onUpdateFilters,
	vehicleButtonLabel,
	passengerButtonLabel,
	editMode,
}: BookingTypeButtonsProps) {
	return (
		<ButtonGroup className="passenger-type-btn-group" alignment={Alignment.HORIZONTAL}>
			<Button
				className={onVehicleTab ? 'selected-btn' : 'unselected-btn'}
				disabled={editMode}
				onClick={() => onTabSelected(wizardData, onUpdateFilters, 'vehicle')}
			>
				{vehicleButtonLabel.toUpperCase()}
			</Button>
			<Button
				className={onPassengerTab ? 'selected-btn' : 'unselected-btn'}
				disabled={editMode}
				onClick={() => onTabSelected(wizardData, onUpdateFilters, 'passenger')}
			>
				{passengerButtonLabel.toUpperCase()}
			</Button>
		</ButtonGroup>
	);
}

function onTabSelected(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData, tab: TabSelected) {
	const newData: BookingWizardData = { ...wizardData };
	newData.tabSelected = tab;

	if (tab === 'vehicle') {
		if (whiteLabelStore.vehicleBookingShouldHaveNoPassengers) {
			// Ensure that there are no passengers
			newData.adultTickets = [];
			newData.childTickets = [];
			newData.infantTickets = [];
			newData.passengerDTickets = [];
			newData.passengerETickets = [];
			newData.passengerFTickets = [];
			newData.passengerGTickets = [];
			newData.passengerHTickets = [];
		}
		newData.tripType = whiteLabelStore.GetVehicleDefaultTripType;
		if (newData.tripType === 'one way') {
			newData.returningTicketId = '';
		}
	}

	if (tab === 'passenger') {
		// Clear all vehicle data from the booking wizard data
		newData.trailerCheckboxSelected = false;
		newData.vehicleLengthId = '';
		newData.vehicleWeightId = '';
		newData.trailerLengthId = '';
		newData.trailerTypeId = 'NO_TRAILER';

		newData.tripType = whiteLabelStore.GetPassengerDefaultTripType;
		if (newData.tripType === 'one way') {
			newData.returningTicketId = '';
		}
	}

	// Reset because additional booking options may not be available for booking type (vehicle/pax)
	newData.departingTripOptions = [];
	newData.returningTripOptions = [];

	onUpdateFilters(newData);
}
