import React from 'react';
import dayjs from 'dayjs';

import { formatTime } from 'Modules/Shared/Utils/DateTime/formatTime';
import { useIsJourneyElementsLoading } from 'Stores/JourneyElementsStore';

interface DepartureArrivalTimeProps {
	departureTime: Date;
	arrivalTime: Date;
}

export default function DepartureArrivalTime({
	departureTime,
	arrivalTime,
}: DepartureArrivalTimeProps) {
	const isLoading = useIsJourneyElementsLoading();

	if (isLoading) {
		return <></>;
	}

	const days = dayjs(arrivalTime).diff(departureTime, 'days');

	return (
		<>
			{formatTime(departureTime)} - {formatTime(arrivalTime)} {days > 0 && <sup>+{days}</sup>}
		</>
	);
}
