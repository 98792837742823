import { LocationEntity, RouteEntity } from 'Models/Entities';
import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
	clearFerryBookingTransactionIdFromStorage,
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import classNames from 'classnames';
import If from 'Views/Components/If/If';
import {
	BookingWizardSidebar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/BookingWizardSidebar';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import {
	BookingWizardData,
	clearBookingWizardData,
	clearOldBookingWizardData,
	saveOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { AuthStep } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/AuthStep';
import { BookingEntity } from 'Models/Entities';
import useAddOns from 'Hooks/useAddOns';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import {
	FerryBookingWizardStepContent,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Components/FerryBookingWizardStepContent';
import { stringIsEmpty } from 'Util/TypeGuards';
import {
	FerryBookingWizardProgressBar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/ProgressBar/FerryBookingWizardProgressBar';
import {
	FerryBookingWizardBottomBar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BottomBar/FerryBookingWizardBottomBar';

export interface BookingWizardProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (wizardData: BookingWizardData) => void;
	selectedTrips: SelectedTrips;
	tabs: BookingWizardTab[];
	baseUrl: string;
	route: RouteEntity | null;
	setRoute: (route: RouteEntity | null) => void;
	isIpad: boolean;
	isMobile: boolean
	disableBackAndContinueButtons: boolean;
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
	disableContinueButton: boolean;
	tabIsLoading: boolean;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
}

export enum VisibilityStatus {
	VISIBLE = 'VISIBLE',
	HIDDEN = 'HIDDEN',
	EXCLUDED = 'EXCLUDED',
}

export enum SidebarStatus {
	FILTERS,
	CART_SUMMARY,
	NO_SIDEBAR,
}

export interface BookingWizardTab {
	name: string;
	displayName: string;
	path: string;
	visibility: VisibilityStatus; // If true, the step will be skipped
	sidebarStatus: SidebarStatus; // If true, show the ferry filters. If false, show the cart summary. not required for mobile
	validateStep: (updateErrorState: boolean) => boolean;
	component: ReactNode;
	authorize: boolean;
	className?: string;
	contentClass?: string;
	showTAndCsButton: boolean;
	hideBottomBar?: boolean;
	continueBtnText?: string;
	continueBtnLoadingText?: string;
	continueBtnProps?: React.ButtonHTMLAttributes<Element>;
	tabTitle: string;
	tabTitleEdit?: string;
	tabIsLoading?: boolean;
	disableContinueButton?: boolean;
}

export function BookingWizard({
	wizardData,
	onUpdateWizardData,
	selectedTrips,
	tabs,
	baseUrl,
	route,
	setRoute,
	isIpad,
	isMobile,
	disableBackAndContinueButtons,
	locations,
	disableContinueButton,
	tabIsLoading,
	setDisableContinueButton,
}: BookingWizardProps) {
	useAddOns(wizardData.userId, false);
	const history = useHistory();
	const pathObjects = window.location.pathname.split('/');
	let currentTabIndex: number = -1;
	const bookingForAlteration = wizardData.wizardMode === 'ALTERATION';

	useEffect(() => {
		if (stringIsEmpty(wizardData.userId) && getFerryBookingTransactionIdFromStorage() !== null) {
			clearFerryBookingTransactionIdFromStorage();
		}
	}, []);
	const setDocTitle = (tabToRender: BookingWizardTab, editMode?: boolean) => {
		if (editMode && tabToRender.tabTitleEdit !== undefined) {
			document.title = tabToRender.tabTitleEdit;
		} else {
			document.title = tabToRender.tabTitle;
		}
	};
	const progressBarClass = 'booking-wizard-progress-bar';

	// TODO: replace with react router (Wizard.tsx: line 104)
	if (pathObjects.length === 2) {
		const firstTab = tabs.find(tab => (tab.visibility !== VisibilityStatus.EXCLUDED));
		history.push(!!firstTab ? `${baseUrl}/${firstTab.path}` : '/404');
		return <></>;
	}

	if (pathObjects.length === 3) {
		currentTabIndex = tabs.findIndex(
			tab => tab.path.toLowerCase() === pathObjects[2].toLowerCase(),
		);

		// This snippet is for if we land on an excluded page. upon landing on an excluded page we should navigate
		// forward to the next visible or hidden page. The reason for this logic is that if we are on a visible/hidden
		// page which changes to excluded, without this snippet we would be navigated to the 404 page immediately
		if (tabs[currentTabIndex].visibility === VisibilityStatus.EXCLUDED) {
			for (let i = currentTabIndex + 1; i < tabs.length; i++) {
				if (tabs[i].visibility !== VisibilityStatus.EXCLUDED) {
					history.push(`${baseUrl}/${tabs[i].path}`);
					return <></>;
				}
			}

			// At this point, we will have not found a valid step to navigate to when the tabs visibility is excluded.
			// If that is the case, then we will let the user be navigated to 404
			currentTabIndex = -1;
		}

		// This snippet will ensure that if the user lands on a page in the wizard and they haven't completed the
		// previous step, then they will be returned to the earliest step that they haven't completed
		for (let i = 0; i < currentTabIndex; i++) {
			if (tabs[i].visibility !== VisibilityStatus.EXCLUDED && !tabs[i].validateStep(false)) {
				history.push(`${baseUrl}/${tabs[i].path}`);
				return <></>;
			}
		}
	}

	// We don't need to do any other checks here, because it the path objects length isn't 3, then tab index won't be
	// reassigned from its initial value of -1
	if (currentTabIndex === -1) {
		history.push('/404');
		return <></>;
	}

	const tabToRender = tabs[currentTabIndex];

	setDocTitle(tabToRender, bookingForAlteration);

	const timerShown = getFerryBookingTransactionIdFromStorage() !== null;
	const containerClass = timerShown && isIpad
		? 'booking-wizard-step-content-container booking-wizard-mobile-timer-padding'
		: 'booking-wizard-step-content-container';
	const contentClass = isIpad
		? ['booking-wizard-step-content-no-top-gap-mobile']
		: []; // default must be undefined as there are default classes in FerryBookingWizardStepContent

	if (!!tabToRender.contentClass) {
		contentClass.push(tabToRender.contentClass);
	}

	const onTimerExpiry = () => {
		const newData = { ...wizardData };
		newData.departureTicketId = '';
		newData.returningTicketId = '';
		newData.bookingToEdit = '';
		clearOldBookingWizardData();
		onUpdateWizardData(newData);
	};

	const bookingWizardStep = (
		<div className="booking-wizard-step-container">
			<If condition={tabToRender.sidebarStatus !== SidebarStatus.NO_SIDEBAR}>
				<BookingWizardSidebar
					wizardData={wizardData}
					selectedTrips={selectedTrips}
					onUpdateWizardData={onUpdateWizardData}
					sidebarStatus={tabToRender.sidebarStatus}
					route={route}
					setRoute={setRoute}
					isIpad={isIpad}
					locations={locations}
				/>
			</If>
			<div className="booking-wizard-step-main-container-horizontal">
				<div className="booking-wizard-step-main-container-vertical">
					<FerryBookingWizardProgressBar
						tabs={tabs}
						progressBarClass={progressBarClass}
						onClickTab={tab => {
							history.push(`${baseUrl}/${tab.path}`);
						}}
						currentTabIndex={currentTabIndex}
						isIpad={isIpad}
						onTimerExpiry={onTimerExpiry}
						ticketsPageUrl={`${baseUrl}/${tabs[1].path}`}
						forEventBooking={false}
						isMobile={isMobile}
						disabled={disableBackAndContinueButtons || tabToRender.path === 'post-payment'}
					/>
					<div className={containerClass}>
						<div className={classNames('booking-wizard-step-content-background', tabToRender.className)}>
							<FerryBookingWizardStepContent
								contentClass={contentClass.length > 0 ? classNames(contentClass) : undefined}
							>
								{tabs[currentTabIndex].component}
								{/* <If condition={route !== null}>
									{tabs[currentTabIndex].component}
								</If> */}
							</FerryBookingWizardStepContent>
						</div>
						<If condition={!tabToRender.hideBottomBar}>
							<FerryBookingWizardBottomBar
								disableContinueButton={disableContinueButton}
								tabIsLoading={tabIsLoading}
								continueBtnProps={tabToRender.continueBtnProps}
								continueBtnText={tabToRender.continueBtnText}
								continueBtnLoadingText={tabToRender.continueBtnLoadingText}
								onTimerExpiry={onTimerExpiry}
								wizardData={wizardData}
								onUpdateWizardData={onUpdateWizardData}
								onClickContinue={() => {
									if (tabToRender.path === 'payment' || disableBackAndContinueButtons) {
										// PaymentTab will handle everything.
										return;
									}

									// Find will get the first array element which fits the criteria, so we
									// can slice out our non completed steps and find the first one of those
									// which isn't excluded and navigate to that
									const nextStep = tabs
										.slice(currentTabIndex + 1)
										.find(tab => tab.visibility !== VisibilityStatus.EXCLUDED);
									if (tabToRender.validateStep(true)) {
										// If there is no next step then that means that the wizard has been
										// completed
										if (nextStep) {
											history.push(!!nextStep ? `${baseUrl}/${nextStep.path}` : '/404');
										}
									} else {
										ScrollToError();
									}
								}}
								onClickBack={() => {
									if (disableBackAndContinueButtons) {
										return;
									}

									setDisableContinueButton(false);

									let previousStep = null;
									if (tabs[currentTabIndex].name === 'Cart' && !bookingForAlteration) {
										saveOldBookingWizardData(wizardData);
									}
									// For the going backwards, we can't use the same find method because we need the
									// last index instead of the first. We could try to slice out the relevant entries
									// and invert it, but I think this works better because it also allows us to more
									// easily handle what happens when the user presses back from the start of the
									// wizard
									for (let i = currentTabIndex - 1; i >= 0; i--) {
										if (tabs[i].visibility === VisibilityStatus.VISIBLE) {
											previousStep = tabs[i];
											break;
										}
									}
									// If previous step is null here, then the user has gone back from the start of the
									// wizard. If that is the case, then we don't allow the user to navigate
									if (previousStep !== null) {
										history.push(`${baseUrl}/${previousStep.path}`);
									} else if (wizardData.bulkBookingTripIds !== undefined) {
										clearOldBookingWizardData();
										clearBookingWizardData();
										clearFerryBookingTransactionIdFromStorage();
										history.push('/ferry-schedule');
									}
								}}
								tabs={tabs}
								currentTabIndex={currentTabIndex}
								isIpad={isIpad}
								ticketsPageUrl={`${baseUrl}/${tabs[1].path}`}
							/>
						</If>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<If condition={tabToRender.authorize}>
				<AuthStep wizardData={wizardData} onUpdateWizardData={onUpdateWizardData}>
					{bookingWizardStep}
				</AuthStep>
			</If>
			<If condition={!tabToRender.authorize}>
				{bookingWizardStep}
			</If>
		</>
	);
}
