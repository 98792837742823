import { minDate, maxDate } from 'Util/_HumanWritten/DateUtils';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import {
	AdditionalOption,
	BookingWizardData,
	TabSelected,
	TripType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

type ConvertToWizardDataOptions = Pick<
	BookingWizardData,
	'wizardMode'
		| 'bookingToEdit'
		| 'departureTrip'
		| 'associatedTripDateTime'
		| 'departingCancellationFee'
		| 'returningCancellationFee'
		| 'departingCancellationCutoffHours'
>;

export function convertToWizardData(
	userId: string,
	summary: BookingSummary,
	options: ConvertToWizardDataOptions,
	returnSummary?: BookingSummary,
): BookingWizardData {
	const {
		wizardMode,
		bookingToEdit,
		departureTrip,
		associatedTripDateTime,
		departingCancellationFee = 0,
		returningCancellationFee = 0,
		departingCancellationCutoffHours = 0,
	} = options;

	if (!summary) {
		throw new Error('Missing summary');
	}
	if (!summary.ferryTrip) {
		throw new Error('Missing summary ferry trip');
	}
	if (!summary.ferryTrip.route) {
		throw new Error('Missing summary ferry trip route');
	}

	const startDate = minDate(
		[summary.ferryTrip.departureDateTime, returnSummary?.ferryTrip.departureDateTime],
	) ?? new Date();
	const endDate = maxDate(
		[summary.ferryTrip?.departureDateTime, returnSummary?.ferryTrip.departureDateTime],
	) ?? new Date();

	const adultTickets = summary.adultPassengerTickets;
	const childTickets = summary.childPassengerTickets;
	const infantTickets = summary.infantPassengerTickets;
	const {
		passengerDTickets,
		passengerETickets,
		passengerFTickets,
		passengerGTickets,
		passengerHTickets,
	} = summary;

	const trailerCheckboxSelected = summary.towOnInfo !== null;
	const tabSelected: TabSelected = summary.cargoInfo ? 'vehicle' : 'passenger';
	const tripType: TripType = returnSummary ? 'return' : 'one way';

	const fromLocationId = summary.ferryTrip.route.departureId;
	const toLocationId = summary.ferryTrip.route.destinationId;
	const ticketSelectionStartDate = startDate;
	const ticketSelectionEndDate = endDate;
	const departureTicketId = summary.ferryTrip.id;
	const returningTicketId = returnSummary?.ferryTrip.id ?? '';

	const {
		driverFirstName,
		driverLastName,
		driverPhone,
		note,
	} = summary;

	const cargoIdentification = summary.cargoInfo?.cargoIdentification ?? '';
	const hiredVehicle = summary.cargoInfo?.tbc ?? false;
	const cargoTypeId = summary.cargoInfo?.cargoTypeId ?? '';
	const cargoMake = summary.cargoInfo?.cargoType.cargoMake ?? '';
	const cargoModel = summary.cargoInfo?.cargoType.cargoModel ?? '';
	const vehicleLengthId = summary.cargoInfo?.selectedLengthId ?? '';
	const vehicleWeightId = summary.cargoInfo?.selectedWeightId ?? '';

	const towOnTypeId = summary.towOnInfo?.towOnTypeId ?? 'NO_TRAILER';
	const trailerLengthId = summary.towOnInfo?.selectedLengthId ?? '';

	const departingTripOptions: AdditionalOption[] = summary.additionalBookingOptions?.map(x => ({
		optionId: x.option.id,
		amount: x.quantity ?? 0,
		optionPrice: x.option?.staticPrice ?? 0,
		optionName: x.option?.name ?? '',
		gstExempt: x.option?.excludeGST,
	})) ?? [];

	const returningTripOptions = returnSummary?.additionalBookingOptions?.map(x => ({
		optionId: x.option.id,
		amount: x.quantity ?? 0,
		optionPrice: x.option?.staticPrice ?? 0,
		optionName: x.option?.name ?? '',
		gstExempt: x.option?.excludeGST,
	})) ?? [];

	return {
		acceptedTsAndCs: false,
		bookingToEdit: bookingToEdit ?? '',
		wizardMode,
		departureTrip,
		associatedTripDateTime,
		startDate,
		endDate,
		adultTickets: adultTickets,
		childTickets: childTickets,
		infantTickets: infantTickets,
		passengerDTickets: passengerDTickets,
		passengerETickets: passengerETickets,
		passengerFTickets: passengerFTickets,
		passengerGTickets: passengerGTickets,
		passengerHTickets: passengerHTickets,
		trailerCheckboxSelected,
		tabSelected,
		tripType,
		fromLocationId,
		toLocationId,
		ticketSelectionStartDate,
		ticketSelectionEndDate,
		departureTicketId,
		returningTicketId,
		userId,
		driverFirstName,
		driverLastName,
		driverPhone,
		cargoIdentification,
		hiredVehicle,
		cargoTypeId,
		cargoMake,
		cargoModel,
		vehicleLengthId,
		vehicleWeightId,
		trailerTypeId: towOnTypeId,
		trailerLengthId,
		departingTripOptions,
		returningTripOptions,
		note,
		departingCancellationFee,
		returningCancellationFee,
		departingCancellationCutoffHours,
	};
}
