/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBookingEntity from 'Models/Security/Acl/VisitorsBookingEntity';
import UserBookingEntity from 'Models/Security/Acl/UserBookingEntity';
import StaffBookingEntity from 'Models/Security/Acl/StaffBookingEntity';
import ManagerBookingEntity from 'Models/Security/Acl/ManagerBookingEntity';
import AdminBookingEntity from 'Models/Security/Acl/AdminBookingEntity';
import InvoicedUserBookingEntity from 'Models/Security/Acl/InvoicedUserBookingEntity';
import FixedPriceUserBookingEntity from 'Models/Security/Acl/FixedPriceUserBookingEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {
	AdditionalBookingOptionEntity,
	FerryTripEntity,
	TowOnEntity,
	CargoEntity,
} from 'Models/Entities';
import * as Enums from '../Enums';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { getFullName, upperCaseFirst } from 'Util/StringUtils';
import { getMeasurementLabelFromId } from 'Util/_HumanWritten/MeasurementUtils';
import * as BookingSummaryDto from 'Views/Components/_HumanWritten/BookingSummaryDto';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import * as FetchBookingsWithFilters from 'Util/_HumanWritten/Bookings/FetchBookingsWithFilters';
import RouteEntity from './RouteEntity';
import {
	getDefaultPassengerInfo,
	PassengersInfo,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	passengerTicketIsEmpty,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Passengers/PassengerTabHelpers';

export function getBaseSummaryAttributes() {
	return `
		userFirstName
		userLastName
		driverPhone
		driverFirstName
		driverLastName
		totalCost
		paidBookingPrice
		maxRefund
		nonRefundableAmount
		note
		adultPassengerTickets {
			id
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		childPassengerTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		infantPassengerTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		passengerDTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		passengerETickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		passengerFTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		passengerGTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
		passengerHTickets {
			firstName
			lastName
			age
			dateOfBirth
			address
			email
			phone
		}
	`;
}

export type CustomBookingStatus = Enums.bookingStatus | 'INVOICED' | 'AWAITING' | 'PAID' | '';

// % protected region % [Add any further imports here] end

export interface IBookingEntityAttributes extends IModelAttributes {
	checkedIn: boolean;
	desynced: boolean;
	humanReadableId: string;
	legacyId: number;
	isInvoiced: boolean;

	alterations: Array<
		| Models.AlterationEntity
		| Models.IAlterationEntityAttributes
	>;
	notes: Array<
		| Models.NoteBooking
		| Models.INoteBookingAttributes
	>;
	userId: string;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	returnBookingForId?: string;
	returnBookingFor?: Models.BookingEntity | Models.IBookingEntityAttributes;
	returnBooking?: Models.BookingEntity |
		Models.IBookingEntityAttributes;
	smsDeliveryRecords: Array<
		| Models.SmsDeliveryRecordEntity
		| Models.ISmsDeliveryRecordEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	bookingSummaries: BookingSummaryDto.BookingSummaries;
	firstTimeOnRoute: boolean;
	/**
	 * This is a custom GraphQL resolver.
	 *
	 * It looks at the status of the booking's alterations to determine it's current status.
	 */
	bookingStatus?: Enums.bookingStatus;
	transactionStatus?: Enums.bookingTransactionStatus;
	isEventBooking: boolean
	/**
	 * This is a custom GraphQL resolver.
	 *
	 * It looks at the status of the booking's alterations and transactions to determine the status to display. Return
	 * value can be PENDING_INVOICE/INVOICED/BOOKED/RESERVED/CANCELLED/EXPIRED_RESERVATION or empty.
	 */
	displayedStatus?: CustomBookingStatus;
	/**
	 * Custom GraphQL resolver.
	 *
	 * True if booking has an alteration linked to a transaction with status INVOICED or PENDING_INVOICE.
	 */
	invoiced?: boolean;
	/**
	 * Custom GraphQL resolver.
	 *
	 * The summary of the booking only using BOOKED alterations.
	 */
	bookedSummary?: BookingSummaryDto.BookingSummary;
	/**
	 * Custom GraphQL resolver.
	 *
	 * The summary of the booking (can be event or ferry booking).
	 */
	bookingListSummary?: FetchBookingsWithFilters.BookingListSummaryDto;
	/**
	 * Custom GraphQL resolver.
	 *
	 * The cancellation percentage that applies to this booking
	 */
	cancellationFee?: number;
	/**
	 * Custom GraphQL resolver.
	 *
	 * The alteration fee that applies to this booking
	 */
	alterationFee?: number;
	/**
	 * Custom GraphQL resolver.
	 *
	 * The hours before departure for the cancellation fee
	 */
	cancellationCutoffHours?: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BookingEntity', 'Booking')
// % protected region % [Customise your entity metadata here] end
export default class BookingEntity extends Model
	implements IBookingEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBookingEntity(),
		new UserBookingEntity(),
		new StaffBookingEntity(),
		new ManagerBookingEntity(),
		new AdminBookingEntity(),
		new InvoicedUserBookingEntity(),
		new FixedPriceUserBookingEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Checked In'] off begin
	/**
	 * True if user has been checked into the trip, false otherwise
	 */
	@observable
	@attribute<BookingEntity, boolean>()
	@CRUD({
		name: 'Checked In',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public checkedIn: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Checked In'] end

	// % protected region % [Modify props to the crud options here for attribute 'Desynced'] off begin
	/**
	 * A boolean for if the linked booking has the same alteration information, and therefore the associated trip can also be edited.
	 */
	@observable
	@attribute<BookingEntity, boolean>()
	@CRUD({
		name: 'Desynced',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public desynced: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Desynced'] end

	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Id'] off begin
	/**
	 * Converts GUID into a 9 digit  number
	 */
	@observable
	@attribute<BookingEntity, string>()
	@CRUD({
		name: 'Human Readable Id',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public humanReadableId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<BookingEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Invoiced'] off begin
	/**
	 * Whether the booking has been invoiced or not.
	 */
	@observable
	@attribute<BookingEntity, boolean>()
	@CRUD({
		name: 'Is Invoiced',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isInvoiced: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Invoiced'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alterations',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.AlterationEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'alterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterations: Models.AlterationEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Note'] off begin
		name: 'Note',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.NoteBooking,
		optionEqualFunc: makeJoinEqualsFunc('noteId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'bookingEntity',
			oppositeEntityName: 'notesEntity',
			relationName: 'booking',
			relationOppositeName: 'note',
			entity: () => Models.BookingEntity,
			joinEntity: () => Models.NoteBooking,
			oppositeEntity: () => Models.NotesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Note'] end
	})
	public notes: Models.NoteBooking[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Return Booking For'] off begin
		name: 'Return Booking For',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.BookingEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'returnBookingFors',
			oppositeEntity: () => Models.BookingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Return Booking For'] end
	})
	public returnBookingForId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public returnBookingFor: Models.BookingEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Return Booking'] off begin
		name: 'Return Booking',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.BookingEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'returnBookings',
			oppositeEntity: () => Models.BookingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Return Booking'] end
	})
	public returnBooking?: Models.BookingEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Sms Delivery Record'] on begin
		name: 'Sms Delivery Records',
		displayType: 'hidden',
		order: 80,
		referenceTypeFunc: () => Models.SmsDeliveryRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'smsDeliveryRecords',
			oppositeEntity: () => Models.SmsDeliveryRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Sms Delivery Record'] end
	})
	public smsDeliveryRecords: Models.SmsDeliveryRecordEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public bookingSummaries: BookingSummaryDto.BookingSummaries;

	/**
	 * This is a custom GraphQL resolver (see BookingEntityType.cs).
	 *
	 * Returns true if this booking is the first time on a route compared the user's booking history.
	 */
	@observable
	public firstTimeOnRoute: boolean;

	@observable
	public bookingStatus?: Enums.bookingStatus;

	@observable
	public displayedStatus?: CustomBookingStatus;

	@observable
	public isEventBooking: boolean;

	@observable
	public invoiced?: boolean;

	@observable
	public bookedSummary?: BookingSummaryDto.BookingSummary;

	@observable
	public bookingListSummary?: FetchBookingsWithFilters.BookingListSummaryDto;

	@observable
	public transactionStatus?: Enums.bookingTransactionStatus;

	@observable
	public alterationFee?: number;

	@observable
	public cancellationFee?: number;

	@observable
	public cancellationCutoffHours?: number;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBookingEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		this.defaultExpands += '\nhumanReadableId';
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBookingEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.checkedIn !== undefined) {
				this.checkedIn = attributes.checkedIn;
			}
			if (attributes.desynced !== undefined) {
				this.desynced = attributes.desynced;
			}
			if (attributes.humanReadableId !== undefined) {
				this.humanReadableId = attributes.humanReadableId;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.isInvoiced !== undefined) {
				this.isInvoiced = attributes.isInvoiced;
			}
			if (attributes.alterations !== undefined && Array.isArray(attributes.alterations)) {
				for (const model of attributes.alterations) {
					if (model instanceof Models.AlterationEntity) {
						this.alterations.push(model);
					} else {
						this.alterations.push(new Models.AlterationEntity(model));
					}
				}
			}
			if (attributes.notes !== undefined && Array.isArray(attributes.notes)) {
				for (const model of attributes.notes) {
					if (model instanceof Models.NoteBooking) {
						this.notes.push(model);
					} else {
						this.notes.push(new Models.NoteBooking(model));
					}
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			if (attributes.returnBookingForId !== undefined) {
				this.returnBookingForId = attributes.returnBookingForId;
			}
			if (attributes.returnBookingFor !== undefined) {
				if (attributes.returnBookingFor === null) {
					this.returnBookingFor = attributes.returnBookingFor;
				} else if (attributes.returnBookingFor instanceof Models.BookingEntity) {
					this.returnBookingFor = attributes.returnBookingFor;
					this.returnBookingForId = attributes.returnBookingFor.id;
				} else {
					this.returnBookingFor = new Models.BookingEntity(attributes.returnBookingFor);
					this.returnBookingForId = this.returnBookingFor.id;
				}
			}
			if (attributes.returnBooking !== undefined) {
				if (attributes.returnBooking === null) {
					this.returnBooking = attributes.returnBooking;
				} else if (attributes.returnBooking instanceof Models.BookingEntity) {
					this.returnBooking = attributes.returnBooking;
				} else {
					this.returnBooking = new Models.BookingEntity(attributes.returnBooking);
				}
			}
			if (attributes.smsDeliveryRecords !== undefined && Array.isArray(attributes.smsDeliveryRecords)) {
				for (const model of attributes.smsDeliveryRecords) {
					if (model instanceof Models.SmsDeliveryRecordEntity) {
						this.smsDeliveryRecords.push(model);
					} else {
						this.smsDeliveryRecords.push(new Models.SmsDeliveryRecordEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (isNotNullOrUndefined(attributes.bookingSummaries)
				&& isNotNullOrUndefined(attributes.bookingSummaries.recentSummary)
			) {
				//
				// New alteration booking summary section
				//
				this.bookingSummaries = {
					recentSummary: new BookingSummary({
						userFirstName: attributes.bookingSummaries.recentSummary.userFirstName,
						userLastName: attributes.bookingSummaries.recentSummary.userLastName,
						adultPassengerTickets: attributes.bookingSummaries.recentSummary.adultPassengerTickets,
						childPassengerTickets: attributes.bookingSummaries.recentSummary.childPassengerTickets,
						infantPassengerTickets: attributes.bookingSummaries.recentSummary.infantPassengerTickets,
						passengerDTickets: attributes.bookingSummaries.recentSummary.passengerDTickets,
						passengerETickets: attributes.bookingSummaries.recentSummary.passengerETickets,
						passengerFTickets: attributes.bookingSummaries.recentSummary.passengerFTickets,
						passengerGTickets: attributes.bookingSummaries.recentSummary.passengerGTickets,
						passengerHTickets: attributes.bookingSummaries.recentSummary.passengerHTickets,
						driverFirstName: attributes.bookingSummaries.recentSummary.driverFirstName,
						driverLastName: attributes.bookingSummaries.recentSummary.driverLastName,
						driverPhone: attributes.bookingSummaries.recentSummary.driverPhone,
						ferryTrip: new FerryTripEntity(attributes.bookingSummaries.recentSummary.ferryTrip),
						totalCost: attributes.bookingSummaries.recentSummary.totalCost,
						maxRefund: attributes.bookingSummaries.recentSummary.maxRefund,
						paidBookingPrice: attributes.bookingSummaries.recentSummary.paidBookingPrice,
						nonRefundableAmount: attributes.bookingSummaries.recentSummary.nonRefundableAmount,
						additionalBookingOptions: [],
						note: attributes.bookingSummaries.recentSummary.note,
					}),
				};
				//
				// New alteration booking summary: CargoInfo
				//
				const { cargoInfo: vehicleInfoFromNewSummary } = attributes
					.bookingSummaries
					.recentSummary;

				if (!isNotNullOrUndefined(vehicleInfoFromNewSummary)) {
					this.bookingSummaries.recentSummary.cargoInfo = null;
				} else if (vehicleInfoFromNewSummary instanceof CargoEntity) {
					this.bookingSummaries.recentSummary.cargoInfo = attributes
						.bookingSummaries
						.recentSummary
						.cargoInfo;
				} else {
					this.bookingSummaries.recentSummary.cargoInfo = new CargoEntity(
						vehicleInfoFromNewSummary,
					);
				}
				//
				// New alteration booking summary: TowOnInfo
				//
				const { towOnInfo: trailerInfoFromNewSummary } = attributes
					.bookingSummaries
					.recentSummary;

				if (!isNotNullOrUndefined(trailerInfoFromNewSummary)) {
					this.bookingSummaries.recentSummary.towOnInfo = null;
				} else if (trailerInfoFromNewSummary instanceof TowOnEntity) {
					this.bookingSummaries.recentSummary.towOnInfo = trailerInfoFromNewSummary;
				} else {
					this.bookingSummaries.recentSummary.towOnInfo = new TowOnEntity(
						trailerInfoFromNewSummary,
					);
				}
				//
				// New alteration booking summary: AdditionalBookingOptions
				//
				const { additionalBookingOptions: optionsFromNewSummary } = attributes
					.bookingSummaries
					.recentSummary;
				if (Array.isArray(optionsFromNewSummary)) {
					this.bookingSummaries
						.recentSummary
						.additionalBookingOptions = optionsFromNewSummary
							.map(option => {
								return {
									quantity: option.quantity,
									option: option.option instanceof AdditionalBookingOptionEntity
										? option.option
										: new AdditionalBookingOptionEntity(option.option),
								};
							});
				}
				//
				// Old alteration booking summary
				//
				if (attributes.bookingSummaries.previousSummary) {
					this.bookingSummaries.previousSummary = new BookingSummary({
						userFirstName: attributes.bookingSummaries.previousSummary.userFirstName,
						userLastName: attributes.bookingSummaries.previousSummary.userLastName,
						adultPassengerTickets: attributes.bookingSummaries.previousSummary.adultPassengerTickets,
						childPassengerTickets: attributes.bookingSummaries.previousSummary.childPassengerTickets,
						infantPassengerTickets: attributes.bookingSummaries.previousSummary.infantPassengerTickets,
						passengerDTickets: attributes.bookingSummaries.previousSummary.passengerDTickets,
						passengerETickets: attributes.bookingSummaries.previousSummary.passengerETickets,
						passengerFTickets: attributes.bookingSummaries.previousSummary.passengerFTickets,
						passengerGTickets: attributes.bookingSummaries.previousSummary.passengerGTickets,
						passengerHTickets: attributes.bookingSummaries.previousSummary.passengerHTickets,
						driverFirstName: attributes.bookingSummaries.previousSummary.driverFirstName,
						driverLastName: attributes.bookingSummaries.previousSummary.driverLastName,
						driverPhone: attributes.bookingSummaries.previousSummary.driverPhone,
						ferryTrip: new FerryTripEntity(attributes.bookingSummaries.previousSummary.ferryTrip),
						totalCost: attributes.bookingSummaries.previousSummary.totalCost,
						paidBookingPrice: attributes.bookingSummaries.previousSummary.paidBookingPrice,
						maxRefund: attributes.bookingSummaries.previousSummary.maxRefund,
						nonRefundableAmount: attributes.bookingSummaries.recentSummary.nonRefundableAmount,
						additionalBookingOptions: [],
					});
					//
					// Old alteration booking summary: CargoInfo
					//
					const { cargoInfo: vehicleInfoFromOldSummary } = attributes
						.bookingSummaries
						.previousSummary;
					if (!isNotNullOrUndefined(vehicleInfoFromOldSummary)) {
						this.bookingSummaries.previousSummary.cargoInfo = null;
					} else if (vehicleInfoFromOldSummary instanceof CargoEntity) {
						this.bookingSummaries.previousSummary.cargoInfo = vehicleInfoFromOldSummary;
					} else {
						this.bookingSummaries.previousSummary.cargoInfo = new CargoEntity(
							vehicleInfoFromOldSummary,
						);
					}
					//
					// Old alteration booking summary: TowOnInfo
					//
					const { towOnInfo: trailerInfoFromOldSummary } = attributes
						.bookingSummaries
						.previousSummary;
					if (!isNotNullOrUndefined(trailerInfoFromOldSummary)) {
						this.bookingSummaries.previousSummary.towOnInfo = null;
					} else if (trailerInfoFromOldSummary instanceof TowOnEntity) {
						this.bookingSummaries.previousSummary.towOnInfo = trailerInfoFromOldSummary;
					} else {
						this.bookingSummaries.previousSummary.towOnInfo = new TowOnEntity(
							trailerInfoFromOldSummary,
						);
					}
					//
					// Old alteration booking summary: AdditionalBookingOptions
					//
					const { additionalBookingOptions: optionsFromOldSummary } = attributes
						.bookingSummaries
						.previousSummary;
					if (Array.isArray(optionsFromOldSummary)) {
						this.bookingSummaries
							.previousSummary
							.additionalBookingOptions = optionsFromOldSummary
								.map(option => {
									return {
										quantity: option.quantity,
										option: option.option instanceof AdditionalBookingOptionEntity
											? option.option
											: new AdditionalBookingOptionEntity(option.option),
									};
								});
					}
				}
			}
			if (attributes.firstTimeOnRoute !== undefined) {
				this.firstTimeOnRoute = attributes.firstTimeOnRoute;
			}
			if (isNotNullOrUndefined(attributes.bookingStatus)) {
				this.bookingStatus = attributes.bookingStatus;
			}
			if (isNotNullOrUndefined(attributes.displayedStatus)) {
				this.displayedStatus = attributes.displayedStatus;
			}
			if (isNotNullOrUndefined(attributes.isEventBooking)) {
				this.isEventBooking = attributes.isEventBooking;
			}
			if (isNotNullOrUndefined(attributes.invoiced)) {
				this.invoiced = attributes.invoiced;
			}
			if (isNotNullOrUndefined(attributes.bookedSummary)) {
				this.bookedSummary = attributes.bookedSummary;

				this.bookedSummary.ferryTrip = new FerryTripEntity(this.bookedSummary.ferryTrip);
				if (isNotNullOrUndefined(this.bookedSummary.cargoInfo)) {
					this.bookedSummary.cargoInfo = new CargoEntity(this.bookedSummary.cargoInfo);
				}
			}
			if (isNotNullOrUndefined(attributes.bookingListSummary)) {
				this.bookingListSummary = attributes.bookingListSummary;

				this.bookingListSummary.route = new RouteEntity(this.bookingListSummary.route);
			}
			if (isNotNullOrUndefined(attributes.transactionStatus)) {
				this.transactionStatus = attributes.transactionStatus;
			}
			if (isNotNullOrUndefined(attributes.alterationFee)) {
				this.alterationFee = attributes.alterationFee;
			}
			if (isNotNullOrUndefined(attributes.cancellationFee)) {
				this.cancellationFee = attributes.cancellationFee;
			}
			if (isNotNullOrUndefined(attributes.cancellationCutoffHours)) {
				this.cancellationCutoffHours = attributes.cancellationCutoffHours;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		alterations {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
			${Models.UserEntity.getFiles().map(f => f.name).join('\n')}
		}
		returnBooking {
			${Models.BookingEntity.getAttributes().join('\n')}
		}
		returnBookingFor {
			${Models.BookingEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			notes: {},
			alterations: {},
			returnBooking: {},
			smsDeliveryRecords: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'notes',
							'returnBooking',
							'returnBookingFor',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	/**
	 * If booking has a vehicle, driver name is used.
	 *
	 * Otherwise user account name is used.
	 */
	public getFullName(fromBookedSummary?: boolean) {
		if (!this.user || (!this.bookingSummaries && !fromBookedSummary)) {
			return null;
		}

		if (fromBookedSummary && !this.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		const {
			cargoInfo,
			driverFirstName,
			driverLastName,
		} = fromBookedSummary && this.bookedSummary
			? this.bookedSummary
			: this.bookingSummaries.recentSummary;

		const isVehicleBooking = isNotNullOrUndefined(cargoInfo);
		const name = isVehicleBooking
			? getFullName(driverFirstName, driverLastName, true)
			: this.user.getFullName(true);

		return name;
	}

	public getFirstName() {
		if (!this.user || !this.bookedSummary) {
			return null;
		}

		const { driverFirstName } = this.bookedSummary;

		if (this.hasVehicle() || driverFirstName) {
			return driverFirstName;
		}

		return this.user.firstName;
	}

	/**
	 * Returns formatted text for adult passenger count
	 *
	 * e.g. 2x Adults
	 */
	public getDisplayAdultCount(): string {
		if (!this.bookedSummary) {
			return '- Adults';
		}
		return `${this.bookedSummary.adultPassengerTickets.length}x Adults`;
	}

	/**
	 * Returns formatted text for child passenger count
	 *
	 * e.g. 2x Children
	 */
	public getDisplayChildCount(): string {
		if (!this.bookedSummary) {
			return '- Children';
		}
		return `${this.bookedSummary.childPassengerTickets.length}x Children`;
	}

	/**
	 * Returns formatted text for infant passenger count
	 *
	 * e.g. 2x Infants
	 */
	public getDisplayInfantCount(): string {
		if (!this.bookedSummary) {
			return '- Infants';
		}
		return `${this.bookedSummary.infantPassengerTickets.length}x Infants`;
	}

	/**
	 * Returns text for make of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleMake(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoType && cargoInfo.cargoType.cargoMake) {
				return cargoInfo.cargoType.cargoMake;
			}
		}

		return '-';
	}

	public getDisplayVehicleLength(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.selectedLengthId) {
				return getMeasurementLabelFromId(cargoInfo.selectedLengthId);
			}
		}

		return '-';
	}

	public getDisplayVehicleWeight(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.selectedWeightId) {
				return getMeasurementLabelFromId(cargoInfo.selectedWeightId);
			}
		}

		return '-';
	}

	/**
	 * Returns text for model of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleModel(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoType && cargoInfo.cargoType.cargoModel) {
				return cargoInfo.cargoType.cargoModel;
			}
		}

		return '-';
	}

	/**
	 * Returns text for rego of vehicle (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayVehicleRego(): string {
		if (this.bookedSummary) {
			const { cargoInfo } = this.bookedSummary;
			if (cargoInfo && cargoInfo.cargoIdentification) {
				return cargoInfo.cargoIdentification;
			}
		}

		return '-';
	}

	/**
	 * Returns text for type of trailer (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayTrailerType(): string {
		if (this.bookedSummary) {
			const { towOnInfo } = this.bookedSummary;
			if (towOnInfo && towOnInfo.towOnType) {
				return upperCaseFirst(towOnInfo.towOnType.label.toLowerCase());
			}
		}

		return '-';
	}

	/**
	 * Returns text for length of trailer (if any)
	 *
	 * Returns '-' if not available.
	 */
	public getDisplayTrailerLength(): string {
		if (this.bookedSummary) {
			const { towOnInfo } = this.bookedSummary;
			if (towOnInfo && towOnInfo.selectedLengthId) {
				return getMeasurementLabelFromId(towOnInfo.selectedLengthId);
			}
		}

		return '-';
	}

	public hasVehicle(): boolean {
		return isNotNullOrUndefined(this.bookedSummary?.cargoInfo)
			|| isNotNullOrUndefined(this.bookingSummaries?.recentSummary?.cargoInfo);
	}

	/**
	 * Create a booking entity with default required values. Particularly for fields in recent summary.
	 */
	static createBlank() {
		return new BookingEntity({
			user: new Models.UserEntity(),
			bookedSummary: new BookingSummary({
				userFirstName: '',
				userLastName: '',
				adultPassengerTickets: [getDefaultPassengerInfo('A')],
				childPassengerTickets: [],
				infantPassengerTickets: [],
				passengerDTickets: [],
				passengerETickets: [],
				passengerFTickets: [],
				passengerGTickets: [],
				passengerHTickets: [],
				driverPhone: '',
				driverFirstName: '',
				driverLastName: '',
				ferryTrip: new Models.FerryTripEntity(),
				totalCost: 0,
				paidBookingPrice: 0,
				maxRefund: 0,
				nonRefundableAmount: 0,
				additionalBookingOptions: [],
			}),
		});
	}

	public GetAllPassengerInfosFromBookedSummary(): PassengersInfo[] {
		if (isNullOrUndefined(this.bookedSummary)) {
			return [];
		}

		const {
			adultPassengerTickets,
			childPassengerTickets,
			infantPassengerTickets,
			passengerDTickets,
			passengerETickets,
			passengerFTickets,
			passengerGTickets,
			passengerHTickets,
		} = this.bookedSummary;
		return [...(adultPassengerTickets ?? []),
			...(childPassengerTickets ?? []),
			...(infantPassengerTickets ?? []),
			...(passengerDTickets ?? []),
			...(passengerETickets ?? []),
			...(passengerFTickets ?? []),
			...(passengerGTickets ?? []),
			...(passengerHTickets ?? [])]
			.filter(x => !passengerTicketIsEmpty(x));
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BookingEntity.prototype, 'created');
CRUD(modifiedAttr)(BookingEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
