import React from 'react';
import { observer } from 'mobx-react';

const FerryScheduleSingleStop = React.lazy(() => import('./FerryScheduleSingleStop'));
const FerryScheduleMultiStop = React.lazy(() => import('../FerryScheduleMultiStop/FerryScheduleMultiStop'));

interface FerryScheduleProps {
	/**
	 * FUTURE: Value is fetched from server
	 */
	multiStopEnabled?: boolean;
}

function FerrySchedule({ multiStopEnabled = true }: FerryScheduleProps) {
	return (
		<React.Suspense fallback={<div />}>
			{multiStopEnabled && <FerryScheduleMultiStop />}
			{!multiStopEnabled && <FerryScheduleSingleStop />}
		</React.Suspense>
	);
}

export default observer(FerrySchedule);
