import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { store } from 'Models/Store';
import { DisableContinueState } from '../CheckInUtils';
import BookingForm, { BookingFormMode } from '../BookingForm/BookingForm';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import useCheckInStore from 'Hooks/useCheckInStore';
import If from 'Views/Components/If/If';
import { CLOSE_BOOKING_FORM_ID, BOOKING_FORM_ID } from '../BookingForm/BookingFormUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import useScreenSize from 'Hooks/useScreenSize';
import useQueryParams from 'Hooks/useQueryParams';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import { showAlterReturnTripModal } from 'Views/Components/_HumanWritten/Modal/AlterReturnTripModalContents';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { getBooking } from 'Services/Api/_HumanWritten/CheckInService';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';
import { CheckInBookingConfirmationModal } from '../Modals/CheckInBookingConfirmationModal';
import { GetModalContentForCheckIn } from '../Modals/CheckInModalHelpers';

export interface CheckInDetailsParams {
	isEditMode?: string;
	applyToReturn?: string;
}

export interface CheckInDetailsProps {
	bookingId: string;
	className?: string;
	alwaysOneInputPerRow?: boolean;
}

function CheckInDetails({
	bookingId,
	className,
	alwaysOneInputPerRow = false,
}: CheckInDetailsProps) {
	const {
		isEditMode = 'false',
		applyToReturn: applyToReturnParam = 'false',
	} = useQueryParams() as CheckInDetailsParams;
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();
	const { state, applyToReturn, responseType } = useCheckInDetails(
		bookingId,
		JSON.parse(isEditMode) as boolean,
		JSON.parse(applyToReturnParam) as boolean,
	);
	const { isIpadMiniPortrait } = useScreenSize();

	if (responseType === 'error') {
		alertToast('Could not find booking', 'error');
		store.routerHistory.replace(routes.base);
	}

	if (responseType === 'loading' || state.booking === undefined) {
		return (
			<LottieSpinner />
		);
	}

	const onClose = () => {
		if (routes.current.includes('passengers') && state?.booking?.id) {
			routes.goToEdit(state.booking.id);
		} else {
			store.routerHistory.replace(routes.base);
		}
	};
	const CheckInConfirmation = async () => {
		if (isNotNullOrUndefined(state.booking)) {
			if (!state.booking.checkedIn) {
				await CheckInBookingConfirmationModal(
					GetModalContentForCheckIn(state.booking),
					() => executeCheckIn(),
					() => {
						return false;
					},
				);
			} else {
				await executeCheckIn();
				return true;
			}
		}
		return false;
	};

	const executeCheckIn = async () => {
		if (isNotNullOrUndefined(state.booking)) {
			try {
				// This will update the booking in the backend and alert toast
				await checkInStore.checkInBooking(state.booking!.id, !state.booking!.checkedIn);

				// Update the checkedIn status of the local state so that btn can switch between undo and check in
				runInAction(() => {
					if (state.booking) {
						state.booking.checkedIn = !state.booking?.checkedIn;
					}
					if (state.booking?.checkedIn) {
						PlayCheckInAudio();
					}
				});
				return true;
			} catch (e) {
				console.error(e);
				alertToast('Check in unsuccessful', 'error');
				return false;
			}
		}
		return false;
	};

	/**
	 * The button using this callback has a form ID, which means clicking on this button will trigger the onSubmit
	 * function of the form.
	 *
	 * We only want to trigger that onSubmit when we are in edit mode.
	 */
	const onConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const handleAlterReturnTrip = (value: boolean) => {
			applyToReturn.current = value;
			const search = `?isEditMode=true&applyToReturn=${value}`;
			store.routerHistory.push({
				pathname: store.routerHistory.location.pathname,
				search,
			});
			runInAction(() => {
				state.mode = BookingFormMode.Edit;
			});
		};

		if (state.mode === BookingFormMode.View) {
			// Ensure we prevent form submission
			e.preventDefault();

			if (state?.booking?.returnBooking
				&& state?.booking?.returnBooking?.checkedIn !== true
				&& !isBeforeNow(state?.booking?.returnBooking?.bookedSummary.ferryTrip.departureDateTime)
				&& state?.booking?.desynced === false
				&& state?.booking?.returnBooking?.bookingStatus === 'BOOKED'
				&& state?.booking?.returnBooking?.bookedSummary?.ferryTrip.closed !== true) {
				showAlterReturnTripModal({
					onCancel: () => handleAlterReturnTrip(false),
					onConfirm: () => handleAlterReturnTrip(true),
				});
			} else {
				handleAlterReturnTrip(false);
			}
		}
	};

	return (
		<div className="check-in__sidebar">
			<div className="check-in__sidebar__body">
				<BookingForm
					key={routes.current}
					className={className}
					booking={state.booking}
					mode={state.mode}
					checkIn={CheckInConfirmation}
					applyToReturn={applyToReturn.current}
					disableState={state}
					oneInputPerRow={isIpadMiniPortrait || alwaysOneInputPerRow}
				/>
			</div>
			<div className="check-in__sidebar__footer">
				<Button
					display={Display.Solid}
					colors={Colors.Secondary}
					sizes={Sizes.Medium}
					key="cancel"
					type="submit"
					buttonProps={{
						// This will trigger onSubmit of form with id CLOSE_BOOKING_FORM_ID
						form: CLOSE_BOOKING_FORM_ID,
					}}
					onClick={onClose}
				>
					{/* eslint-disable-next-line no-nested-ternary */}
					{state.mode === BookingFormMode.Edit
						? (routes.current.includes('passengers')
							? 'Back'
							: 'Cancel')
						: 'Close'}
				</Button>
				<Button
					display={Display.Solid}
					colors={
						state.continue
							? Colors.Disabled
							: Colors.Primary
					}
					sizes={Sizes.Medium}
					key="confirm"
					type="submit"
					buttonProps={{
						// This will trigger onSubmit of form with id BOOKING_FORM_ID, unless default is prevented
						form: BOOKING_FORM_ID,
					}}
					onClick={onConfirm}
					disabled={state.continue}
				>
					<If condition={state.mode === BookingFormMode.View}>
						Edit <Icon name="edit-box" />
					</If>
					<If condition={state.mode !== BookingFormMode.View}>
						Next <Icon name="arrow-right" />
					</If>
				</Button>
			</div>
		</div>
	);
}

export default observer(CheckInDetails);

export interface CheckInDetailsState extends DisableContinueState {
	booking?: CheckInBookingOverviewDto;
	mode: BookingFormMode;
}

function useCheckInDetails(id: string, isEditMode: boolean, applyToReturnParam: boolean = false) {
	const state = useLocalStore<CheckInDetailsState>(() => ({
		booking: undefined,
		mode: isEditMode ? BookingFormMode.Edit : BookingFormMode.View,
		continue: false,
	}));

	const applyToReturn = React.useRef<boolean>(applyToReturnParam);
	const response = useAsync(() => getBooking(id), [id]);

	React.useEffect(() => {
		if (response.data) {
			const { data } = response.data;

			runInAction(() => {
				// Important to create new instance of booking entity to have access to class methods
				state.booking = new CheckInBookingOverviewDto(data);
			});
		}
	}, [response.data]);

	return {
		state,
		applyToReturn,
		responseType: response.type,
	};
}
