import { filterDestinations } from 'Stores/JourneyElementsStore';
import { handleChangeEndLocation } from '../Utils/handleChangeEndLocation';
import { handleChangeStartLocation } from '../Utils/handleChangeStartLocation';
import { handleResetLocations } from '../Utils/handleResetLocations';
import { handleSwitchLocations } from '../Utils/handleSwitchLocations';

export interface TripSelectorState {
	startLocationId: string;
	endLocationId: string;
	possibleDestinationIds: string[];
}

export enum TripSelectorActionType {
	ChangeStartLocation = 'change-start-location',
	ChangeEndLocation = 'change-end-location',
	ResetLocations = 'reset-locations',
	SwitchLocations = 'switch-locations',
}

export type TripSelectorActionEvent =
	| { type: TripSelectorActionType.ChangeStartLocation; startLocationId: string }
	| { type: TripSelectorActionType.ChangeEndLocation; endLocationId: string }
	| { type: TripSelectorActionType.ResetLocations; startLocationId: string; endLocationId: string }
	| { type: TripSelectorActionType.SwitchLocations };

export const tripSelectorReducer = (state: TripSelectorState, action: TripSelectorActionEvent): TripSelectorState => {
	switch (action.type) {
		case TripSelectorActionType.ChangeStartLocation: {
			const { startLocationId } = action;
			const {
				startLocationId: newStartLocationId,
				endLocationId,
				possibleDestinationIds,
			} = handleChangeStartLocation(startLocationId, state.endLocationId);

			return {
				...state,
				startLocationId: newStartLocationId,
				endLocationId,
				possibleDestinationIds,
			};
		}

		case TripSelectorActionType.ChangeEndLocation: {
			const { endLocationId } = action;
			const { endLocationId: newEndLocationId } = handleChangeEndLocation(endLocationId);

			return {
				...state,
				endLocationId: newEndLocationId,
			};
		}

		case TripSelectorActionType.ResetLocations: {
			const { startLocationId, endLocationId } = action;
			const {
				startLocationId: newStartLocationId,
				endLocationId: newEndLocationId,
				possibleDestinationIds,
			} = handleResetLocations(startLocationId, endLocationId);

			return {
				...state,
				startLocationId: newStartLocationId,
				endLocationId: newEndLocationId,
				possibleDestinationIds,
			};
		}

		case TripSelectorActionType.SwitchLocations: {
			const {
				startLocationId: newStartLocationId,
				endLocationId: newEndLocationId,
				possibleDestinationIds,
			} = handleSwitchLocations(state.startLocationId, state.endLocationId);

			return {
				...state,
				startLocationId: newStartLocationId,
				endLocationId: newEndLocationId,
				possibleDestinationIds,
			};
		}

		default:
			return state;
	}
};

export const initialState = (startLocationId: string, endLocationId: string): TripSelectorState => ({
	startLocationId,
	endLocationId,
	possibleDestinationIds: filterDestinations(startLocationId).map(x => x.id),
});
