import useDeviceDetect from 'Hooks/useDeviceDetect';
import { useHistory } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useBookingWizardWatch } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/useBookingWizardWatch';
import { fetchTripsForWizard } from './BookingWizardHelpers';
import { isNotNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import alertToast from 'Util/ToastifyUtils';
// import { FerryFilters } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/FerryFilters';
import { FerryFilters } from 'Modules/MultiStopWizard/FerryFilters';
import { TicketsTab } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TicketsComponent';
import { getAddOnsTabVisibility } from 'Util/_HumanWritten/BookingWizard/BookingWizardWrapUtils';
import AddOnsTab from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/AddOn/AddOnTab';
import { CustomLocationState } from 'Models/_HumanWritten/LocationState';
import { checkIfInvalidName, upperCaseFirst } from 'Util/StringUtils';
import { VehicleTab } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Vehicle/VehicleTab';
import {
	ReservationTab,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Reservation/ReservationTab';
import { CartTab } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartTab';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import useStore from 'Hooks/useStore';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { calculateTotalPassengers } from 'Util/_HumanWritten/CapacityCalculationUtils';
import {
	CargoTypeEntity,
	FerryTripEntity,
	LocationEntity,
	RouteEntity,
} from 'Models/Entities';
import {
	canNavigateToCartStep,
	canNavigateToPaymentStep,
	canNavigateToPostPaymentStep,
	FetchFullBookingById,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	BookingWizardData,
	saveBookingWizardDataToLocalStorage,
	saveOldBookingWizardData,
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	BookingWizard,
	BookingWizardTab,
	SidebarStatus,
	VisibilityStatus,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import { onWizardSuccess } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import locationAndRoutesStore from 'Models/LocationAndRoutesStore';
import useAddOns from 'Hooks/useAddOns';
import { ferryBookingToWizardData } from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';
import CustomerTab from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Customer/CustomerTab';
import {
	TermsAndConditions,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/TermsAndConditions/TermsAndConditions';
import {
	PaymentTabWrap,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Payment/PaymentTabWrap';
import {
	PostPaymentTab,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/PostPayment/PostPaymentTab';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import useLocationAndRoutes from 'Hooks/useLocationAndRoutes';
import { PAYMENT_FORM_ID } from '../Payment/usePayment';
import { appConfigStore } from 'Models/AppConfigStore';
import { PassengersTab } from './WizardSteps/Passengers/PassengersTab';
import {
	combineAllPassengerTickets,
	validatePassengers,
} from './WizardSteps/Passengers/PassengerTabHelpers';

type BookingWizardWrapInnerProps = {
	bookingWizardData: BookingWizardData,
	setBookingWizardData: React.Dispatch<React.SetStateAction<BookingWizardData | undefined>>
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
};

export function BookingWizardWrapInner({
	bookingWizardData,
	setBookingWizardData,
	locations,
}: BookingWizardWrapInnerProps) {
	const baseUrl = '/booking-wizard';
	const { isIpad, isMobile } = useDeviceDetect();
	const history = useHistory();
	const store = useStore();
	const { isStaff } = store;

	// We are only storing locations in the new wizard data instead of routes, as storing the route would be duplication
	// of information, and introduces a potential method for how our data can get out of sync. To combat this, we store
	// the route in the wizard so it can be accessed by any components which need it
	const [route, setRoute] = useState<RouteEntity | null>(null);
	const [cargoType, setVehicleType] = useState<CargoTypeEntity | null>(null);
	const [selectedTrips, setSelectedTrips] = useState<SelectedTrips>({});
	const [bulkBookingTrips, setBulkBookingTrips] = useState<FerryTripEntity[] | null>(null);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [
		bookingWizardErrors,
		setBookingWizardErrors,
	] = useState<WizardErrors<BookingWizardData>>({} as WizardErrors<BookingWizardData>);
	const [bookingCreationFailed, setBookingCreationFailed] = useState<boolean>(false);
	const [disableContinueButton, setDisableContinueButton] = useState<boolean>(false);
	const [tabIsLoading, setTabIsLoading] = useState<boolean>(false);

	// by storing this in a state variable, it will not be updated in the future when the user clicks the button to
	// accept the t and cs
	/**
	 * True when user has entered their payment details and pressed 'Pay now'.
	 */
	const [paymentDetailsEntered, setPaymentDetailsEntered] = useState<boolean>(false);
	const [pageLoaded, setPageLoaded] = useState<boolean>(false);
	const [acceptedTandCsStatic] = useState(bookingWizardData.acceptedTsAndCs);

	useBookingWizardWatch({ bookingWizardData, paymentDetailsEntered });
	useAddOns();
	usePassengerTypes();
	useLocationAndRoutes();

	useEffect(() => {
		fetchTripsForWizard(
			bookingWizardData.departureTicketId ?? '',
			bookingWizardData.returningTicketId ?? '',
			selectedTrips,
			(trips: SelectedTrips) => {
				setSelectedTrips(trips);
			},
			bookingWizardData.userId,
		);
	}, [
		bookingWizardData.departureTicketId,
		bookingWizardData.returningTicketId,
		bookingWizardData.userId,
	]);

	useEffect(() => {
		if (bookingWizardData.bookingToEdit !== ''
			&& bookingWizardData.bookingToEdit !== undefined
			&& bookingWizardData.departureTicketId === ''
		) {
			FetchFullBookingById(
				bookingWizardData.bookingToEdit,
				null,
				true,
			).then(x => {
				const bookingAsWizardData = ferryBookingToWizardData(
					isNotNullOrUndefined(x) ? [x] : [],
					undefined,
					selectedTrips,
					true,
					(bookingWizardData.departureTrip === undefined),
				)[0];
				saveOldBookingWizardData(bookingAsWizardData.wizardData);
				updateDataCallback(bookingAsWizardData.wizardData);
				fetchTripsForWizard(
					bookingAsWizardData.wizardData.departureTicketId ?? '',
					bookingAsWizardData.wizardData.returningTicketId ?? '',
					selectedTrips,
					(trips: SelectedTrips) => {
						setSelectedTrips(trips);
					},
					bookingWizardData.userId,
				);
				history.push('/booking-wizard/vehicle');
			});
		}
	}, [bookingWizardData.bookingToEdit, bookingWizardData.userId]);

	if (bookingWizardData.cargoTypeId !== '' && cargoType === null) {
		CargoTypeEntity.fetchAndCount<CargoTypeEntity>({
			id: bookingWizardData.cargoTypeId,
		}).then(fetchedVehicleType => {
			if (fetchedVehicleType.count > 0) {
				setVehicleType(fetchedVehicleType.data[0]);
			}
		}).catch(_ => {
			setVehicleType(null);
		});
	}

	// Everything that updates the state of this variable also needs to set the field in local storage. Therefore, this
	// function should be used for all onAfterUpdate props
	const onUpdateWizardData = (wizardData: BookingWizardData) => {
		saveBookingWizardDataToLocalStorage(wizardData);
		setBookingWizardData(wizardData);
	};

	const updateDataCallback = useCallback((wizardData: BookingWizardData) => {
		onUpdateWizardData(wizardData);
	}, []);

	if (store.loggedIn && !isStaff && bookingWizardData.userId === '') {
		const newData = { ...bookingWizardData };
		newData.userId = store.userId ?? '';
		updateDataCallback(newData);
	}

	const tabs: BookingWizardTab[] = [
		{
			name: 'Search',
			displayName: 'Search',
			path: 'search',
			className: 'search-step',
			tabTitle: 'Search trip',
			visibility: isIpad && bookingWizardData.bulkBookingTripIds === undefined
				? VisibilityStatus.VISIBLE
				: VisibilityStatus.EXCLUDED,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (
					canNavigateToCartStep(history)
					|| canNavigateToPaymentStep(history)
					|| canNavigateToPostPaymentStep(history)
				) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				if (whiteLabelStore.minAdultsForVehicle > 0 || bookingWizardData.tabSelected === 'passenger') {
					// Minimum passenger count validation
					if (calculateTotalPassengers(bookingWizardData) <= 0) {
						newErrors.adultTickets = 'Minimum of 1 passenger required';
						alertToast(
							'You must add a passenger to your trip to continue.',
							'error',
							'Select passengers',
						);
					}
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<FerryFilters
					wizardData={bookingWizardData}
					onUpdateFilters={updateDataCallback}
					route={route}
					setRoute={setRoute}
					locations={locations}
				/>
			),
			authorize: false,
			showTAndCsButton: false,
		},
		{
			name: 'Tickets',
			displayName: 'Tickets',
			path: 'tickets',
			tabTitle: 'Tickets',
			tabTitleEdit: 'Edit tickets',
			visibility: bookingWizardData.bulkBookingTripIds === undefined
				? VisibilityStatus.VISIBLE
				: VisibilityStatus.EXCLUDED,
			sidebarStatus: SidebarStatus.FILTERS,
			validateStep: updateErrorState => {
				// Tickets is skipped when running the wizard in bulk booking mode. This means that we don't need to do
				// validation for this step
				if (bookingWizardData.bulkBookingTripIds !== undefined) {
					return true;
				}

				const newErrors: WizardErrors<BookingWizardData> = {};
				if (
					canNavigateToCartStep(history)
					|| canNavigateToPaymentStep(history)
					|| canNavigateToPostPaymentStep(history)
				) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				// One-way ferry trip validation
				if (bookingWizardData.departureTicketId === '') {
					newErrors.departureTicketId = 'Need to select a valid ferry trip';
					if (updateErrorState) {
						alertToast(
							'You must select a ticket for your departing ferry trip to continue.',
							'error',
							'Select departing trip',
						);
					}
				}

				// Return ferry trip validation
				if (bookingWizardData.returningTicketId === '' && bookingWizardData.tripType === 'return') {
					newErrors.returningTicketId = 'Need to select a valid ferry trip';
					if (updateErrorState) {
						alertToast(
							'You must select a ticket for your returning ferry trip to continue.',
							'error',
							'Select returning trip',
						);
					}
				}

				if (whiteLabelStore.minAdultsForVehicle > 0 || bookingWizardData.tabSelected === 'passenger') {
					// Minimum passenger count validation
					if (calculateTotalPassengers(bookingWizardData) <= 0) {
						newErrors.adultTickets = 'Minimum of 1 passenger required';
						alertToast(
							'You must add a passenger to your trip to continue.',
							'error',
							'Select passengers',
						);
					}
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}

				// Feature: Multi-stop - hacky solution to ensure route is set
				if (selectedTrips.departingTrip) {
					setRoute(selectedTrips.departingTrip.route);
				}

				return Object.keys(newErrors).length === 0;
			},
			component: (
				<TicketsTab
					wizardData={bookingWizardData}
					onUpdateWizardData={updateDataCallback}
					route={route}
					selectedTrips={selectedTrips}
				/>
			),
			authorize: false,
			showTAndCsButton: false,
		},
		{
			name: 'Customer',
			displayName: 'Customer',
			path: 'customer',
			tabTitle: 'Customer details',
			tabTitleEdit: 'Edit customer details',
			visibility: !isStaff
				? VisibilityStatus.EXCLUDED
				: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};
				if (canNavigateToCartStep(history) || canNavigateToPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				if (bookingWizardData.userId === '') {
					newErrors.userId = 'A customer must be selected';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<CustomerTab
					wizardData={bookingWizardData}
					onUpdateData={updateDataCallback}
					errors={bookingWizardErrors}
					locationState={history.location.state as CustomLocationState}
					onUpdateErrors={setBookingWizardErrors}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Vehicle',
			displayName: whiteLabelStore.config.vehicleLabel,
			path: 'vehicle',
			tabTitle: `${upperCaseFirst(whiteLabelStore.config.vehicleLabel)} details`,
			tabTitleEdit: `Edit ${whiteLabelStore.config.vehicleLabel} details`,
			visibility: bookingWizardData.tabSelected === 'vehicle'
				? VisibilityStatus.VISIBLE
				: VisibilityStatus.EXCLUDED,
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (canNavigateToCartStep(history) || canNavigateToPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				if (bookingWizardData.cargoIdentification === '') {
					newErrors.cargoIdentification = 'Required field';
				}

				if (bookingWizardData.cargoMake === '') {
					newErrors.cargoMake = 'Required field';
				}

				if (bookingWizardData.cargoModel === '') {
					newErrors.cargoModel = 'Required field';
				}

				if (bookingWizardData.vehicleLengthId === '') {
					newErrors.vehicleLengthId = 'Required field';
				}

				// eslint-disable-next-line max-len
				if (bookingWizardData.trailerTypeId !== 'NO_TRAILER' && bookingWizardData.trailerLengthId === '' && whiteLabelStore.config.trailersEnabled) {
					newErrors.trailerLengthId = 'Required field';
				}

				if (bookingWizardData.driverFirstName === '') {
					newErrors.driverFirstName = 'Required field';
				}
				if (checkIfInvalidName(bookingWizardData.driverFirstName ?? '')) {
					newErrors.driverFirstName = 'No spaces. A-Z and \'-\' only.';
				}

				if (bookingWizardData.driverLastName === '') {
					newErrors.driverLastName = 'Required field';
				}
				if (checkIfInvalidName(bookingWizardData.driverLastName ?? '')) {
					newErrors.driverLastName = 'No spaces. A-Z and \'-\' only.';
				}

				if (bookingWizardData.driverPhone === '') {
					newErrors.driverPhone = 'Required field';
				}

				if (!IsValidPhone(bookingWizardData.driverPhone)) {
					newErrors.driverPhone = 'Enter a valid phone number';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<VehicleTab
					wizardData={bookingWizardData}
					onUpdateWizardData={updateDataCallback}
					isCustomer={!isStaff}
					route={route}
					cargoType={cargoType}
					setVehicleType={setVehicleType}
					previousCargo={{ previous: false }}
					errors={bookingWizardErrors}
					onUpdateErrors={setBookingWizardErrors}
					selectedTrips={selectedTrips}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Passengers',
			displayName: 'Passengers',
			path: 'passengers',
			tabTitle: 'Passengers',
			tabTitleEdit: 'Edit Passengers',
			visibility: appConfigStore.showPassengerTab(bookingWizardData) ? VisibilityStatus.VISIBLE : VisibilityStatus.EXCLUDED,
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (canNavigateToCartStep(history) || canNavigateToPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				const errors = validatePassengers(combineAllPassengerTickets(bookingWizardData));
				if (isNotNullOrUndefined(errors) && errors.length > 0) {
					newErrors.adultTickets = 'Required field';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<PassengersTab
					wizardData={bookingWizardData}
					onUpdateWizardData={updateDataCallback}
					errors={bookingWizardErrors}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Add ons',
			displayName: 'Add ons',
			path: 'add-ons',
			tabTitle: 'Add-ons',
			tabTitleEdit: 'Edit add-ons',
			visibility: getAddOnsTabVisibility(bookingWizardData.tabSelected, selectedTrips),
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: () => true,
			component: (
				<>
					{route && (
						<AddOnsTab
							wizardData={bookingWizardData}
							route={route}
							selectedTrips={selectedTrips}
							onUpdateWizardData={updateDataCallback}
						/>
					)}
				</>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Reservation',
			displayName: 'Reservation',
			path: 'reservation',
			// this hidden step will be between vehicle and cart. We don't tell the user that this step exists so we
			// don't want a document title for it. Instead we will keep the same title from the previous step as it will
			// appear to the user that they are on the same page and waiting to progress to cart
			tabTitle: 'Vehicle details',
			tabTitleEdit: 'Edit vehicle details',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (canNavigateToCartStep(history) || canNavigateToPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
						return true;
					}
				}

				// To get to cart step, there must exist a transactionId. If not, it means this reservation step has
				// been skipped. Returning false will then allow this step to be executed.
				return !bookingCreationFailed && getFerryBookingTransactionIdFromStorage() !== null;
			},
			component: (
				<ReservationTab
					wizardData={bookingWizardData}
					bulkFerryTrips={bulkBookingTrips}
					onUpdateWizardData={updateDataCallback}
					navigateToNextStep={() => {
						//
						// Important to use history.replace so that when the user clicks back on the browser, they will
						// be navigated to previous visible tab.
						//
						history.replace(`${baseUrl}/cart`);
					}}
					navigateToPreviousStep={() => {
						// Set our previous step url like this because regardless of whether the user is staff, we will
						// navigate to vehicle tab if the booking is a vehicle booking. That allows us to safely
						// override whatever we set first
						let previousStepUrl = isStaff ? 'customer' : 'tickets';
						if (bookingWizardData.tabSelected === 'vehicle') {
							previousStepUrl = 'vehicle';
						}
						history.push(`${baseUrl}/${previousStepUrl}`);
					}}
					navigateToTickets={() => {
						history.push(`${baseUrl}/tickets`);
					}}
					onCompleteBookingAttempt={setBookingCreationFailed}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Cart',
			displayName: 'Cart',
			path: 'cart',
			tabTitle: 'Cart',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (canNavigateToCartStep(history) || canNavigateToPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
				}

				// If the user clicks through too fast then there can be problems related to the transaction id not
				// being present when the user tries to get the ts and cs. To solve this we can ensure that the user
				// can't progress unless the transaction id is set. This will only happen if the user is clicking
				// through the site really fast
				return getFerryBookingTransactionIdFromStorage() !== null;
			},
			component: (
				<CartTab
					wizardData={bookingWizardData}
					selectedTrips={selectedTrips}
					onUpdateWizardData={updateDataCallback}
					setSelectedTrips={setSelectedTrips}
					isIpad={isIpad}
					setRefresh={setRefresh}
					refresh={refresh}
					setTabIsLoading={setTabIsLoading}
				/>
			),
			authorize: true,
			className: 'cart-step',
			showTAndCsButton: false,
		},
		{
			name: 'Ts-and-Cs',
			displayName: 'Ts-and-Cs',
			path: 't-and-cs',
			tabTitle: 'Terms and Conditions',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (!bookingWizardData.acceptedTsAndCs) {
					newErrors.acceptedTsAndCs = 'Must accept terms and conditions to continue';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<TermsAndConditions
					acceptedTsandCs={acceptedTandCsStatic}
					navigateToNextStep={() => {
						history.push(`${baseUrl}/payment`);
					}}
					isMobile={isMobile}
					termsAndConditionsType={bookingWizardData.tabSelected}
					isAlteration={bookingWizardData.wizardMode === 'ALTERATION'}
					bookingToEditId={bookingWizardData.bookingToEdit}
				/>
			),
			authorize: true,
			className: 'cart-step',
			showTAndCsButton: true,
		},
		{
			name: 'Payment',
			displayName: 'Payment',
			path: 'payment',
			tabTitle: 'Payment',
			className: 'payment-step',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				if (updateErrorState) {
					setBookingWizardErrors({});
				}
				return true;
			},
			component: (
				<PaymentTabWrap
					timeoutExpiryUrl={`${baseUrl}/cart`}
					paymentCompletedUrl={`${baseUrl}/post-payment`}
					nameToPrefill={store.userData !== undefined && !isStaff
						? `${store.userData.firstName} ${store.userData.lastName}`
						: ''}
					onCompletePayment={() => {
						setPaymentDetailsEntered(true);
					}}
					onPageLoaded={() => {
						setPageLoaded(true);
					}}
					bulkBooking={bookingWizardData.bulkBookingBookingIds !== undefined}
					returnTrip={bookingWizardData.tripType === 'return'}
					userId={bookingWizardData.userId}
					isAlteration={bookingWizardData.wizardMode === 'ALTERATION'}
					bookingToEditId={bookingWizardData.bookingToEdit}
					eventBooking={false}
					setTabIsLoading={setTabIsLoading}
					setDisableContinueButton={setDisableContinueButton}
					disableContinueButton={disableContinueButton}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
			//
			// When on the PaymentTab, we want to use the continue button to trigger the
			// payment form. We do this by setting the formId of the button and set type
			// to 'submit'.
			//
			continueBtnProps: {
				form: PAYMENT_FORM_ID,
				type: 'submit',
				disabled: disableContinueButton || tabIsLoading,
			},
			continueBtnText: 'Pay now',
			continueBtnLoadingText: 'Processing...',
			hideBottomBar: !pageLoaded,
		},
		{
			name: 'Post Payment',
			displayName: 'Post Payment',
			path: 'post-payment',
			tabTitle: 'Payment',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				if (updateErrorState) {
					setBookingWizardErrors({});
				}
				return true;
			},
			component: (
				<PostPaymentTab
					onSuccess={(transactionId: string) => {
						onWizardSuccess(
							store,
							transactionId,
							bookingWizardData.wizardMode === 'ALTERATION',
							bookingWizardData.returningTicketId !== '',
							false,
							bookingWizardData.userId,
						);
					}}
					onPaymentFailed={errorCode => {
						history.push(`${baseUrl}/payment?ErrorCode=${errorCode}`);
					}}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
			contentClass: 'post-payment-step-content',
			hideBottomBar: true,
		},
	];

	// useEffect(() => {
	// 	// We do two different fetches for the route because there were some problems which arose if the selected route
	// 	// didn't have a return route. This was due to multiple state variables being set at once, so this one will set
	// 	// the route whenever the wizard data changes, and the one below will set the route initially based on the wizard
	// 	// data
	// 	if (stringNotEmpty(bookingWizardData.fromLocationId) && stringNotEmpty(bookingWizardData.toLocationId)) {
	// 		const routeEntity = locationAndRoutesStore.getRouteByLocationIds(bookingWizardData.fromLocationId, bookingWizardData.toLocationId);

	// 		if (isNotNullOrUndefined(routeEntity)) {
	// 			if (routeEntity.departureId !== bookingWizardData.fromLocationId
	// 				|| routeEntity.destinationId !== bookingWizardData.toLocationId) {
	// 				const newData = { ...bookingWizardData };

	// 				newData.fromLocationId = routeEntity.departureId;
	// 				newData.toLocationId = routeEntity.destinationId;
	// 				updateDataCallback(newData);
	// 			}

	// 			setRoute(routeEntity);
	// 		}
	// 	}
	// }, [bookingWizardData.toLocationId, bookingWizardData.fromLocationId, locationAndRoutesStore.routes, locationAndRoutesStore.locations]);

	return (
		<BookingWizard
			wizardData={bookingWizardData}
			selectedTrips={selectedTrips}
			onUpdateWizardData={updateDataCallback}
			tabs={tabs}
			baseUrl="/booking-wizard"
			route={route}
			setRoute={setRoute}
			isIpad={isIpad}
			isMobile={isMobile}
			disableBackAndContinueButtons={paymentDetailsEntered}
			setDisableContinueButton={setDisableContinueButton}
			disableContinueButton={disableContinueButton}
			tabIsLoading={tabIsLoading}
			locations={locations}
		/>
	);
}
