/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsFerryTripEntity from 'Models/Security/Acl/VisitorsFerryTripEntity';
import UserFerryTripEntity from 'Models/Security/Acl/UserFerryTripEntity';
import StaffFerryTripEntity from 'Models/Security/Acl/StaffFerryTripEntity';
import ManagerFerryTripEntity from 'Models/Security/Acl/ManagerFerryTripEntity';
import AdminFerryTripEntity from 'Models/Security/Acl/AdminFerryTripEntity';
import InvoicedUserFerryTripEntity from 'Models/Security/Acl/InvoicedUserFerryTripEntity';
import FixedPriceUserFerryTripEntity from 'Models/Security/Acl/FixedPriceUserFerryTripEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
import type {
	IPassengerSpaceCapacity,
	IVehicleSpaceCapacity,
	IWeightSpaceCapacity,
} from '../CapacityEntities';
import {
	formatAMPM,
	formatDateDayMonth,
	formatDayMonthYear,
	formatMilitary,
} from 'Util/_HumanWritten/TimeUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { getDayOfTheWeek } from 'Util/_HumanWritten/DayOfWeekUtils';
import { measurementPrice } from 'Services/CustomGql/FerryTripEntityType';

export interface CommunicationTabContactsCount {
	mobileNumberCount: number;
	emailAddressesCount: number;
}

// % protected region % [Add any further imports here] end

export interface IFerryTripEntityAttributes extends IModelAttributes {
	name: string;
	vehicleOverbookingAllowance: number;
	passengerOverbookingAllowance: number;
	startDate: Date;
	endDate: Date;
	startTime: Date;
	endTime: Date;
	adultPassengerPrice: number;
	childPassengerPrice: number;
	infantPassengerPrice: number;
	legacyId: number;
	disabled: boolean;
	hidden: boolean;
	priceD: number;
	priceE: number;
	priceF: number;
	priceG: number;
	priceH: number;
	showAdditionalIcon: boolean;
	closed: boolean;
	preventAutomaticClosing: boolean;
	noteIconId: string;
	noteIcon: Blob;

	alterations: Array<
		| Models.AlterationEntity
		| Models.IAlterationEntityAttributes
	>;
	communicationsRecords: Array<
		| Models.CommunicationsRecordEntity
		| Models.ICommunicationsRecordEntityAttributes
	>;
	eventDetailId?: string;
	eventDetail?: Models.EventDetailEntity | Models.IEventDetailEntityAttributes;
	ferryId?: string;
	ferry?: Models.FerryEntity | Models.IFerryEntityAttributes;
	routeId?: string;
	route?: Models.RouteEntity | Models.IRouteEntityAttributes;
	notes: Array<
		| Models.NotesEntity
		| Models.INotesEntityAttributes
	>;
	prices: Array<
		| Models.PriceEntity
		| Models.IPriceEntityAttributes
	>;
	smsDeliveryRecords: Array<
		| Models.SmsDeliveryRecordEntity
		| Models.ISmsDeliveryRecordEntityAttributes
	>;
	tripStops: Array<
		| Models.TripStopEntity
		| Models.ITripStopEntityAttributes
	>;
	waitlistItems: Array<
		| Models.WaitlistItemEntity
		| Models.IWaitlistItemEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	departureDateTime: Date | null;
	capacity?: number | null;
	arrivalDateTime: Date | null;
	passengerSpaceCapacity?: IPassengerSpaceCapacity | null;
	vehicleSpaceCapacity?: IVehicleSpaceCapacity | null;
	weightSpaceCapacity?: IWeightSpaceCapacity | null;

	// Dynamic passenger prices
	dynamicAdultPassengerPrice?: number;
	dynamicChildPassengerPrice?: number;
	dynamicInfantPassengerPrice?: number;
	dynamicPriceD?: number;
	dynamicPriceE?: number;
	dynamicPriceF?: number;
	dynamicPriceG?: number;
	dynamicPriceH?: number;

	dynamicMeasurementPrices?: measurementPrice[];
	alterationssCount?: number | null;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FerryTripEntity', 'Ferry Trip')
// % protected region % [Customise your entity metadata here] end
export default class FerryTripEntity extends Model
	implements IFerryTripEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFerryTripEntity(),
		new UserFerryTripEntity(),
		new StaffFerryTripEntity(),
		new ManagerFerryTripEntity(),
		new AdminFerryTripEntity(),
		new InvoicedUserFerryTripEntity(),
		new FixedPriceUserFerryTripEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'startDate',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<FerryTripEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Overbooking Allowance'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Vehicle Overbooking Allowance',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public vehicleOverbookingAllowance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Overbooking Allowance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Overbooking Allowance'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Passenger Overbooking Allowance',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerOverbookingAllowance: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Overbooking Allowance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] off begin
	@Validators.Required()
	@observable
	@attribute<FerryTripEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Start Date',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public startDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'End Date'] off begin
	@observable
	@attribute<FerryTripEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'End Date',
		displayType: 'datepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public endDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'End Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Time'] off begin
	@Validators.Required()
	@observable
	@attribute<FerryTripEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseTime(a) })
	@CRUD({
		name: 'Start Time',
		displayType: 'timepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'time' }),
		displayFunction: (attr: Date) => attr ? moment(attr).format('HH:mm:ss') : 'None',
	})
	public startTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Start Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'End Time'] off begin
	@observable
	@attribute<FerryTripEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseTime(a) })
	@CRUD({
		name: 'End Time',
		displayType: 'timepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'time' }),
		displayFunction: (attr: Date) => attr ? moment(attr).format('HH:mm:ss') : 'None',
	})
	public endTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'End Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adult Passenger Price'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Adult Passenger Price',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public adultPassengerPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Adult Passenger Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Child Passenger Price'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Child Passenger Price',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public childPassengerPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Child Passenger Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Infant Passenger Price'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Infant Passenger Price',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public infantPassengerPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Infant Passenger Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	@observable
	@attribute<FerryTripEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] off begin
	/**
	 * Whether or a not a ferry trip is hidden from non-staff users.
	 */
	@observable
	@attribute<FerryTripEntity, boolean>()
	@CRUD({
		name: 'Hidden',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hidden: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price D'] off begin
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Price D',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceD: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price D'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price E'] off begin
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Price E',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceE: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price E'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price F'] off begin
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Price F',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceF: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price F'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price G'] off begin
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Price G',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceG: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price G'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price H'] off begin
	@Validators.Numeric()
	@observable
	@attribute<FerryTripEntity, number>()
	@CRUD({
		name: 'Price H',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceH: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price H'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Additional Icon'] off begin
	/**
	 * Whether or not to show the additional icon for ferry trips.
	 */
	@observable
	@attribute<FerryTripEntity, boolean>()
	@CRUD({
		name: 'Show Additional Icon',
		displayType: 'checkbox',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showAdditionalIcon: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show Additional Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Closed'] off begin
	/**
	 * Whether or not the ferry trip is closed from taking customer bookings.
	 */
	@observable
	@attribute<FerryTripEntity, boolean>()
	@CRUD({
		name: 'Closed',
		displayType: 'checkbox',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public closed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Closed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Prevent Automatic Closing'] off begin
	/**
	 * If this is checked, the ferry trip will not automatically close in the background job again.
	 */
	@observable
	@attribute<FerryTripEntity, boolean>()
	@CRUD({
		name: 'Prevent Automatic Closing',
		displayType: 'checkbox',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public preventAutomaticClosing: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Prevent Automatic Closing'] end

	// % protected region % [Modify props to the crud options here for attribute 'Note Icon'] off begin
	@observable
	@attribute<FerryTripEntity, string>({ file: 'noteIcon' })
	@CRUD({
		name: 'Note Icon',
		displayType: 'file',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'noteIcon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Ferry Trip" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public noteIconId: string;

	@observable
	public noteIcon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Note Icon'] end

	/**
	 * The relationship between an alteration and a ferry trip
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] on begin
		name: 'Alterations',
		displayType: 'hidden',
		order: 160,
		referenceTypeFunc: () => Models.AlterationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'alterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterations: Models.AlterationEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Communications Record'] on begin
		name: 'Communications Records',
		displayType: 'hidden',
		order: 180,
		referenceTypeFunc: () => Models.CommunicationsRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'communicationsRecords',
			oppositeEntity: () => Models.CommunicationsRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Communications Record'] end
	})
	public communicationsRecords: Models.CommunicationsRecordEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] off begin
		name: 'Event Detail',
		displayType: 'reference-combobox',
		order: 250,
		referenceTypeFunc: () => Models.EventDetailEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'eventDetails',
			oppositeEntity: () => Models.EventDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] end
	})
	public eventDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public eventDetail: Models.EventDetailEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry'] off begin
		name: 'Ferry',
		displayType: 'reference-combobox',
		order: 260,
		referenceTypeFunc: () => Models.FerryEntity,
		// % protected region % [Modify props to the crud options here for reference 'Ferry'] end
	})
	public ferryId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public ferry: Models.FerryEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Route'] off begin
		name: 'Route',
		displayType: 'reference-combobox',
		order: 270,
		referenceTypeFunc: () => Models.RouteEntity,
		// % protected region % [Modify props to the crud options here for reference 'Route'] end
	})
	public routeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public route: Models.RouteEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Note'] off begin
		name: 'Notes',
		displayType: 'reference-multicombobox',
		order: 280,
		referenceTypeFunc: () => Models.NotesEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'notes',
			oppositeEntity: () => Models.NotesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Note'] end
	})
	public notes: Models.NotesEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price'] on begin
		name: 'Prices',
		displayType: 'hidden',
		order: 180,
		referenceTypeFunc: () => Models.PriceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'prices',
			oppositeEntity: () => Models.PriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Price'] end
	})
	public prices: Models.PriceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Sms Delivery Record'] on begin
		name: 'Sms Delivery Records',
		displayType: 'hidden',
		order: 220,
		referenceTypeFunc: () => Models.SmsDeliveryRecordEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'smsDeliveryRecords',
			oppositeEntity: () => Models.SmsDeliveryRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Sms Delivery Record'] end
	})
	public smsDeliveryRecords: Models.SmsDeliveryRecordEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Trip Stop'] off begin
		name: 'Trip Stops',
		displayType: 'reference-multicombobox',
		order: 310,
		referenceTypeFunc: () => Models.TripStopEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tripStops',
			oppositeEntity: () => Models.TripStopEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Trip Stop'] end
	})
	public tripStops: Models.TripStopEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] on begin
		name: 'Waitlist Items',
		displayType: 'hidden',
		order: 190,
		referenceTypeFunc: () => Models.WaitlistItemEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'waitlistItems',
			oppositeEntity: () => Models.WaitlistItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] end
	})
	public waitlistItems: Models.WaitlistItemEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFerryTripEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFerryTripEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.vehicleOverbookingAllowance !== undefined) {
				this.vehicleOverbookingAllowance = attributes.vehicleOverbookingAllowance;
			}
			if (attributes.passengerOverbookingAllowance !== undefined) {
				this.passengerOverbookingAllowance = attributes.passengerOverbookingAllowance;
			}
			if (attributes.startDate !== undefined) {
				if (attributes.startDate === null) {
					this.startDate = attributes.startDate;
				} else {
					this.startDate = moment(attributes.startDate).toDate();
				}
			}
			if (attributes.endDate !== undefined) {
				if (attributes.endDate === null) {
					this.endDate = attributes.endDate;
				} else {
					this.endDate = moment(attributes.endDate).toDate();
				}
			}
			if (attributes.startTime !== undefined) {
				if (attributes.startTime === null) {
					this.startTime = attributes.startTime;
				} else {
					this.startTime = moment(attributes.startTime, 'HH:mm:ss').toDate();
				}
			}
			if (attributes.endTime !== undefined) {
				if (attributes.endTime === null) {
					this.endTime = attributes.endTime;
				} else {
					this.endTime = moment(attributes.endTime, 'HH:mm:ss').toDate();
				}
			}
			if (attributes.adultPassengerPrice !== undefined) {
				this.adultPassengerPrice = attributes.adultPassengerPrice;
			}
			if (attributes.childPassengerPrice !== undefined) {
				this.childPassengerPrice = attributes.childPassengerPrice;
			}
			if (attributes.infantPassengerPrice !== undefined) {
				this.infantPassengerPrice = attributes.infantPassengerPrice;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.hidden !== undefined) {
				this.hidden = attributes.hidden;
			}
			if (attributes.priceD !== undefined) {
				this.priceD = attributes.priceD;
			}
			if (attributes.priceE !== undefined) {
				this.priceE = attributes.priceE;
			}
			if (attributes.priceF !== undefined) {
				this.priceF = attributes.priceF;
			}
			if (attributes.priceG !== undefined) {
				this.priceG = attributes.priceG;
			}
			if (attributes.priceH !== undefined) {
				this.priceH = attributes.priceH;
			}
			if (attributes.showAdditionalIcon !== undefined) {
				this.showAdditionalIcon = attributes.showAdditionalIcon;
			}
			if (attributes.closed !== undefined) {
				this.closed = attributes.closed;
			}
			if (attributes.preventAutomaticClosing !== undefined) {
				this.preventAutomaticClosing = attributes.preventAutomaticClosing;
			}
			if (attributes.noteIcon !== undefined) {
				this.noteIcon = attributes.noteIcon;
			}
			if (attributes.noteIconId !== undefined) {
				this.noteIconId = attributes.noteIconId;
			}
			if (attributes.alterations !== undefined && Array.isArray(attributes.alterations)) {
				for (const model of attributes.alterations) {
					if (model instanceof Models.AlterationEntity) {
						this.alterations.push(model);
					} else {
						this.alterations.push(new Models.AlterationEntity(model));
					}
				}
			}
			if (attributes.communicationsRecords !== undefined && Array.isArray(attributes.communicationsRecords)) {
				for (const model of attributes.communicationsRecords) {
					if (model instanceof Models.CommunicationsRecordEntity) {
						this.communicationsRecords.push(model);
					} else {
						this.communicationsRecords.push(new Models.CommunicationsRecordEntity(model));
					}
				}
			}
			if (attributes.eventDetailId !== undefined) {
				this.eventDetailId = attributes.eventDetailId;
			}
			if (attributes.eventDetail !== undefined) {
				if (attributes.eventDetail === null) {
					this.eventDetail = attributes.eventDetail;
				} else if (attributes.eventDetail instanceof Models.EventDetailEntity) {
					this.eventDetail = attributes.eventDetail;
					this.eventDetailId = attributes.eventDetail.id;
				} else {
					this.eventDetail = new Models.EventDetailEntity(attributes.eventDetail);
					this.eventDetailId = this.eventDetail.id;
				}
			}
			if (attributes.ferryId !== undefined) {
				this.ferryId = attributes.ferryId;
			}
			if (attributes.ferry !== undefined) {
				if (attributes.ferry === null) {
					this.ferry = attributes.ferry;
				} else if (attributes.ferry instanceof Models.FerryEntity) {
					this.ferry = attributes.ferry;
					this.ferryId = attributes.ferry.id;
				} else {
					this.ferry = new Models.FerryEntity(attributes.ferry);
					this.ferryId = this.ferry.id;
				}
			}
			if (attributes.routeId !== undefined) {
				this.routeId = attributes.routeId;
			}
			if (attributes.route !== undefined) {
				if (attributes.route === null) {
					this.route = attributes.route;
				} else if (attributes.route instanceof Models.RouteEntity) {
					this.route = attributes.route;
					this.routeId = attributes.route.id;
				} else {
					this.route = new Models.RouteEntity(attributes.route);
					this.routeId = this.route.id;
				}
			}
			if (attributes.notes !== undefined && Array.isArray(attributes.notes)) {
				for (const model of attributes.notes) {
					if (model instanceof Models.NotesEntity) {
						this.notes.push(model);
					} else {
						this.notes.push(new Models.NotesEntity(model));
					}
				}
			}
			if (attributes.prices !== undefined && Array.isArray(attributes.prices)) {
				for (const model of attributes.prices) {
					if (model instanceof Models.PriceEntity) {
						this.prices.push(model);
					} else {
						this.prices.push(new Models.PriceEntity(model));
					}
				}
			}
			if (attributes.smsDeliveryRecords !== undefined && Array.isArray(attributes.smsDeliveryRecords)) {
				for (const model of attributes.smsDeliveryRecords) {
					if (model instanceof Models.SmsDeliveryRecordEntity) {
						this.smsDeliveryRecords.push(model);
					} else {
						this.smsDeliveryRecords.push(new Models.SmsDeliveryRecordEntity(model));
					}
				}
			}
			if (attributes.tripStops !== undefined && Array.isArray(attributes.tripStops)) {
				for (const model of attributes.tripStops) {
					if (model instanceof Models.TripStopEntity) {
						this.tripStops.push(model);
					} else {
						this.tripStops.push(new Models.TripStopEntity(model));
					}
				}
			}
			if (attributes.waitlistItems !== undefined && Array.isArray(attributes.waitlistItems)) {
				for (const model of attributes.waitlistItems) {
					if (model instanceof Models.WaitlistItemEntity) {
						this.waitlistItems.push(model);
					} else {
						this.waitlistItems.push(new Models.WaitlistItemEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (isNotNullOrUndefined(attributes.capacity)) {
				this.capacity = attributes.capacity;
			}

			if (isNotNullOrUndefined(attributes.passengerSpaceCapacity)) {
				const passengerSpaceCapacity: IPassengerSpaceCapacity = {};
				if (attributes.passengerSpaceCapacity.used !== undefined) {
					passengerSpaceCapacity.used = attributes.passengerSpaceCapacity.used;
				}
				if (attributes.passengerSpaceCapacity.remaining !== undefined) {
					passengerSpaceCapacity.remaining = attributes.passengerSpaceCapacity.remaining;
				}
				if (attributes
					.passengerSpaceCapacity
					.overbookingAllowanceUsed !== undefined
				) {
					passengerSpaceCapacity
						.overbookingAllowanceUsed = attributes.passengerSpaceCapacity.overbookingAllowanceUsed;
				}
				if (attributes
					.passengerSpaceCapacity
					.overbookingAllowanceRemaining !== undefined) {
					passengerSpaceCapacity.overbookingAllowanceRemaining = attributes
						.passengerSpaceCapacity.overbookingAllowanceRemaining;
				}
				this.passengerSpaceCapacity = passengerSpaceCapacity;
			}

			if (isNotNullOrUndefined(attributes.vehicleSpaceCapacity)) {
				const vehicleSpaceCapacity: IVehicleSpaceCapacity = {};
				if (attributes.vehicleSpaceCapacity.used !== undefined) {
					vehicleSpaceCapacity.used = attributes.vehicleSpaceCapacity.used;
				}
				if (attributes.vehicleSpaceCapacity.remaining !== undefined) {
					vehicleSpaceCapacity.remaining = attributes.vehicleSpaceCapacity.remaining;
				}
				if (attributes.vehicleSpaceCapacity.overbookingAllowanceUsed !== undefined) {
					vehicleSpaceCapacity
						.overbookingAllowanceUsed = attributes.vehicleSpaceCapacity.overbookingAllowanceUsed;
				}
				if (attributes.vehicleSpaceCapacity.overbookingAllowanceRemaining !== undefined) {
					vehicleSpaceCapacity
						.overbookingAllowanceRemaining = attributes.vehicleSpaceCapacity.overbookingAllowanceRemaining;
				}
				if (attributes.vehicleSpaceCapacity.trailerUsed !== undefined) {
					vehicleSpaceCapacity.trailerUsed = attributes.vehicleSpaceCapacity.trailerUsed;
				}
				if (attributes.vehicleSpaceCapacity.trailerRemaining !== undefined) {
					vehicleSpaceCapacity.trailerRemaining = attributes.vehicleSpaceCapacity.trailerRemaining;
				}
				this.vehicleSpaceCapacity = vehicleSpaceCapacity;
			}

			if (isNotNullOrUndefined(attributes.weightSpaceCapacity)) {
				const weightSpaceCapacity: IWeightSpaceCapacity = {};
				if (attributes.weightSpaceCapacity.used !== undefined) {
					weightSpaceCapacity.used = attributes.weightSpaceCapacity.used;
				}
				if (attributes.weightSpaceCapacity.remaining !== undefined) {
					weightSpaceCapacity.remaining = attributes.weightSpaceCapacity.remaining;
				}
				this.weightSpaceCapacity = weightSpaceCapacity;
			}

			if (isNotNullOrUndefined(attributes.dynamicAdultPassengerPrice)) {
				this.dynamicAdultPassengerPrice = attributes.dynamicAdultPassengerPrice;
			}

			if (isNotNullOrUndefined(attributes.dynamicChildPassengerPrice)) {
				this.dynamicChildPassengerPrice = attributes.dynamicChildPassengerPrice;
			}

			if (isNotNullOrUndefined(attributes.dynamicInfantPassengerPrice)) {
				this.dynamicInfantPassengerPrice = attributes.dynamicInfantPassengerPrice;
			}

			if (isNotNullOrUndefined(attributes.dynamicPriceD)) {
				this.dynamicPriceD = attributes.dynamicPriceD;
			}

			if (isNotNullOrUndefined(attributes.dynamicPriceE)) {
				this.dynamicPriceE = attributes.dynamicPriceE;
			}

			if (isNotNullOrUndefined(attributes.dynamicPriceF)) {
				this.dynamicPriceF = attributes.dynamicPriceF;
			}

			if (isNotNullOrUndefined(attributes.dynamicPriceG)) {
				this.dynamicPriceG = attributes.dynamicPriceG;
			}

			if (isNotNullOrUndefined(attributes.dynamicPriceH)) {
				this.dynamicPriceH = attributes.dynamicPriceH;
			}

			if (isNotNullOrUndefined(attributes.dynamicMeasurementPrices)) {
				this.dynamicMeasurementPrices = attributes.dynamicMeasurementPrices;
			}

			if (isNotNullOrUndefined(attributes.alterationssCount)) {
				this.alterationssCount = attributes.alterationssCount;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		departureDateTime
		alterations {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
		ferry {
			${Models.FerryEntity.getAttributes().join('\n')}
		}
		route {
			${Models.RouteEntity.getAttributes().join('\n')}
		}
		prices {
			${Models.PriceEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	@observable
	public capacity: number;

	@computed
	public get departureDateTime(): Date {
		if (!this.startDate || !this.startTime) {
			return new Date();
		}
		return new Date(
			this.startDate.getFullYear(),
			this.startDate.getMonth(),
			this.startDate.getDate(),
			this.startTime.getHours(),
			this.startTime.getMinutes(),
			this.startTime.getSeconds(),
		);
	}

	public set departureDateTime(value) {
		this.startDate = value;
		this.startTime = value;
	}

	@computed
	public get arrivalDateTime(): Date {
		if (!this.startDate || !this.startTime || !this.route) {
			return new Date();
		}
		const date = moment(this.departureDateTime).add(this.route.duration, 'minutes');
		return date.toDate();
	}

	/**
	 * Format departure date time as 12:30pm.
	 */
	@computed
	public get departTimeFormatted(): string | null {
		if (!this.startTime) {
			return null;
		}
		return formatAMPM(this.startTime);
	}

	/**
	 * Format arrival date time as 12:30pm.
	 */
	@computed
	public get arrivalTimeFormatted(): string | null {
		if (!this.arrivalDateTime) {
			return null;
		}
		return formatAMPM(this.arrivalDateTime);
	}

	/**
	 * Format arrival date time as 10:00am - 12:30pm.
	 */
	@computed
	public get tripTimeFormatted(): string | null {
		return `${this.departTimeFormatted ?? ''} - ${this.arrivalTimeFormatted ?? ''}`;
	}

	@observable
	public passengerSpaceCapacity: IPassengerSpaceCapacity;

	@observable
	public vehicleSpaceCapacity: IVehicleSpaceCapacity;

	@observable
	public weightSpaceCapacity: IWeightSpaceCapacity;

	@observable
	public dynamicAdultPassengerPrice: number;

	@observable
	public dynamicChildPassengerPrice: number;

	@observable
	public dynamicInfantPassengerPrice: number;

	@observable
	public dynamicMeasurementPrices: measurementPrice[];

	@observable
	public dynamicPriceD: number;

	@observable
	public dynamicPriceE: number;

	@observable
	public dynamicPriceF: number;

	@observable
	public dynamicPriceG: number;

	@observable
	public dynamicPriceH: number;

	/**
	 * Custom graphQL field. Returns the number of associated alterations.
	 */
	@observable
	public alterationssCount: number;

	/**
	 * Example: 10:00 - 11:30am, B > M
	 */
	public getShortDisplayName() {
		return `${formatDateDayMonth(this.startDate)} ${this.tripTimeFormatted}, `
			+ `${this.route?.departure?.shortName} > ${this.route?.destination?.shortName}`;
	}

	/**
	 * Example: 10:00 - 11:30am, Tue 25 Oct 2022
	 */
	public getDisplayTimeDayDate() {
		const momentDateTime = moment(this.departureDateTime);
		return `${this.tripTimeFormatted}, `
			+ `${getDayOfTheWeek(momentDateTime.day(), false)} `
			+ `${momentDateTime.format('DD MMM YYYY')}`;
	}

	/**
	 * Example: 10:00 - 11:30am
	 */
	public getTripTimeFormatted() {
		return this.tripTimeFormatted;
	}

	/**
	 * Example: 10:00 - 11:30
	 */
	public getTripDateTimeFormatted() {
		if (!this.departureDateTime) {
			return null;
		}

		const departureTimeFormatted = formatMilitary(this.departureDateTime);
		const departureDateFormatted = formatDayMonthYear(this.departureDateTime);

		return `${departureDateFormatted}, ${departureTimeFormatted}`;
	}

	/**
	 * Example: Brisbane - Moreton Island
	 */
	public getFormattedTripRoute() {
		return `${this?.route?.departure?.name} to ${this?.route?.destination?.name}`;
	}

	public getDepartureName() {
		return `${this?.route?.departure?.name}`;
	}

	public getDestinationName() {
		return `${this?.route?.destination?.name}`;
	}

	/**
	 * Example: Riverfire
	 */
	public getEventName() {
		return `${this?.eventDetail?.name}`;
	}

	/**
	 * Example:
	 * !fullMonth: 29 Jun 2023
	 * fullMonth: 29 June 2023
	 */
	public getFormattedTripDateLong(fullMonth?: boolean) {
		const formatString = fullMonth ? 'DD MMMM YYYY' : 'DD MMM YYYY';
		return `${moment(this.startDate).format(formatString)}`;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FerryTripEntity.prototype, 'created');
CRUD(modifiedAttr)(FerryTripEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
