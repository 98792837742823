import React from 'react';

import { LocationEntity, RouteEntity } from 'Models/Entities';
import { TripDetails } from 'Modules/TripDetails/TripDetails';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { FerryFilterPassengers } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Sidebar/FerryFilters/PassengerDetails/FerryFilterPassengers';

export interface FerryFiltersProps {
	wizardData: BookingWizardData;
	onUpdateFilters: (wizardData: BookingWizardData) => void;
	/**
	 * @deprecated
	 */
	route?: RouteEntity | null;
	/**
	 * @deprecated
	 */
	setRoute?: (route: RouteEntity | null) => void;
	/**
	 * @deprecated
	 */
	locations?: LocationEntity[];
}

export function FerryFilters({
	onUpdateFilters,
	wizardData,
	route,
	setRoute,
	locations,
}: FerryFiltersProps) {
	// const { isIpad } = useDeviceDetect();
	// const [showMap, setShowMap] = useState(false);

	// if (route === null) {
	// 	return <LottieSpinner />;
	// }

	return (
		<div className="trip-filters__container">
			<TripDetails
				model={{
					tripTypeTabSelected: wizardData.tripType,
					fromLocation: wizardData.fromLocationId,
					toLocation: wizardData.toLocationId,
					startDate: wizardData.departureTrip === false
						? wizardData.associatedTripDateTime ?? wizardData.startDate
						: wizardData.startDate,
					endDate: wizardData.departureTrip === true
						? wizardData.associatedTripDateTime ?? wizardData.endDate
						: wizardData.endDate,
					route: new RouteEntity(),
					wizardData: wizardData,
				}}
				locations={locations}
				departureTrip={wizardData.departureTrip}
				typeSelected={wizardData.tripType}
				onTabSelected={newTripType => {
					const newData: BookingWizardData = { ...wizardData };
					newData.tripType = newTripType;
					if (newData.tripType === 'one way') {
						newData.returningTicketId = '';
					}
					onUpdateFilters(newData);
				}}
				onDatesChanged={(date, departureDate, updateOtherDate) => {
					if (date !== undefined) {
						const newData: BookingWizardData = { ...wizardData };
						if (departureDate || updateOtherDate) {
							newData.startDate = date;
							newData.ticketSelectionStartDate = date;
						}
						if (!departureDate || updateOtherDate) {
							newData.endDate = date;
							newData.ticketSelectionEndDate = date;
						}
						onUpdateFilters(newData);
					}
				}}
				onUpdateRouteDetails={(fromLocation, toLocation, newRoute) => {
					const newData: BookingWizardData = { ...wizardData };
					newData.fromLocationId = fromLocation;
					newData.toLocationId = toLocation;

					// Reset because additional booking options may not be
					// available in the new route/ferry
					newData.departingTripOptions = [];
					newData.returningTripOptions = [];

					onUpdateFilters(newData);
				}}
			/>
			<div className="dashed-border" />
			{/* <If condition={isIpad}>
				<RouteMap
					isMobile={isIpad}
					currentRoute={route.id}
					locationsAndRoutes={locationAndRoutesStore}
				/>
			</If> */}
			<FerryFilterPassengers
				wizardData={wizardData}
				onUpdateFilters={onUpdateFilters}
			/>
		</div>
	);
}
