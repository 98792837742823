import { filterDestinations, isTravelPossible } from 'Stores/JourneyElementsStore';
import { AppError } from 'Validators/AppError';

const message = 'handleSwitchLocations should never be called when start and end location cannot be switched.';

export const handleSwitchLocations = (oldStartLocationId: string, oldEndLocationId: string) => {
	if (isTravelPossible(oldEndLocationId, oldStartLocationId)) {
		const possibleDestinationIds = filterDestinations(oldEndLocationId).map(x => x.id);
		return { startLocationId: oldEndLocationId, endLocationId: oldStartLocationId, possibleDestinationIds };
	}
	throw new AppError(message, { oldStartLocationId, oldEndLocationId });
};
