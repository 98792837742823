import axios from 'axios';

import { JOURNEY_API } from 'Constants';
import {
	ILocationEntityAttributes,
	IRouteEntityAttributes,
	ISegmentEntityAttributes,
	IStopEntityAttributes,
	LocationEntity,
	RouteEntity,
	SegmentEntity,
	StopEntity,
} from 'Models/Entities';
import { buildUrl } from 'Util/FetchUtils';

export interface JourneyElementsDto {
	locations: LocationEntity[];
	routes: RouteEntity[];
	segments: SegmentEntity[];
	stops: StopEntity[];
}

interface JourneyElementsDtoPartial {
	locations: Partial<ILocationEntityAttributes>[];
	routes: Partial<IRouteEntityAttributes>[];
	segments: Partial<ISegmentEntityAttributes>[];
	stops: Partial<IStopEntityAttributes>[];
}

export async function fetchJourneyElements(): Promise<JourneyElementsDto> {
	const results = await axios.get<JourneyElementsDtoPartial>(buildUrl(`${JOURNEY_API}/elements`));
	return {
		locations: results.data.locations.map(x => new LocationEntity(x)),
		routes: results.data.routes.map(x => new RouteEntity(x)),
		segments: results.data.segments.map(x => new SegmentEntity(x)),
		stops: results.data.stops.map(x => new StopEntity(x)),
	};
}
